import React from "react";
import "../styles/signutureClub.css";
import SignatureClubBG from "../images/signatureClubBG.png";
import TrendingHeaderDash from "../svg/trendingProductDash.svg";


const widthOutput = window.screen.width;
const trendingHeader = (1.8 / 100) * widthOutput;
const inputFontSize = (1 / 100) * widthOutput;


const trendingHeaderStyle = {
    fontSize: `${trendingHeader}px`,
    fontWeight:800
}

const mainDivStyle = {
    backgroundImage: `url(${SignatureClubBG})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
}

export const SignutureClub = () => {
    return (<>
        <div style={mainDivStyle} className="mainDIv_SignatureCLub">
            <div className="innerMainDiv_SignatureCLub">
                <div className="headerDiv_SignatureCLub">
                    <h1 style={trendingHeaderStyle}><img src={TrendingHeaderDash} />&nbsp; join our signature club &nbsp; <img src={TrendingHeaderDash} /></h1>
                </div>
                <div className="inputAndBtnMainDiv_SignatureCLub">
                    <input
                        type="text"
                        placeholder="Enter Your Email"
                        style={{
                            fontSize: `${inputFontSize}px`
                        }}
                    />
                    <button style={{
                        fontSize: `${inputFontSize * 1.1}px`
                    }}>
                        Subscribe
                    </button>
                </div>
                <p style={{
                    fontSize: `${inputFontSize *1.03}px`
                }}>
                    Subscribe to get exclusive offers on new products and to stay update on promos and events.
                </p>
            </div>
        </div>
    </>)
}