import React from "react";
import Layout from "./layout";
import { HomePage } from "../container/homePage";
import { TrendingProduct } from "../container/trendingProduct";
import { ShopByCategories } from "../container/shopByCategories";
import { BannerBelowCategories } from "../container/bannerBelowCategories";
import { OurSpeciality } from "../container/ourSpeaciality";
import { OurCustomerSay } from "../container/ourCustomerSay";
import { SignutureClub } from "../container/signutureClub";

const Dashboard = () => {
    return (<>
        <Layout>
            <HomePage />
            <TrendingProduct />
            <ShopByCategories />
            <BannerBelowCategories />
            <OurSpeciality />
            <OurCustomerSay />
            <SignutureClub />
        </Layout>
    </>)
}
export default Dashboard;