import Layout from '../../component/layout';
import '../../styles/Cart/cart.css';
import React, { useState } from 'react';
import filledstar from '../../svg/Wishlist/filteredstar.svg';
import deleteicon from '../../svg/Cart/deleteicon.svg';
import cartimg from '../../svg/Cart/cart_img.svg';
import cartimg1 from '../../svg/Cart/cart_img.svg';
import cartimg2 from '../../svg/Cart/cart_img.svg';
import cartimg3 from '../../svg/Cart/cart_img.svg';
import cartimg4 from '../../svg/Cart/cart_img.svg';
import { useContext } from 'react';
import noteContext from '../../context/noteContext';
import { useEffect } from 'react';
import { pagePaths } from '../../utils/constant';
import BreadCrum from '../../component/BreadCrum';

export default function Cart() {

    const [wishlistItems, setWishlistItems] =
        useState([
            {
                name: 'Fresh Sunflower Oil',
                oilquantity: '990ml',
                imageSrc: cartimg,
                alt: 'alt',
                currentPrice: 210,
                originalPrice: 650,
            }, {
                name: 'Fresh Sunflower Oil',
                oilquantity: '990ml',
                imageSrc: cartimg1,
                // imageSrc: '../../svg/Cart/deleteicon.svg',
                alt: 'alt',
                currentPrice: 100,
                originalPrice: 580,
            }, {
                name: 'Fresh Sunflower Oil',
                oilquantity: '990ml',
                imageSrc: cartimg2,
                // imageSrc: '../../svg/Cart/deleteicon.svg',
                alt: 'alt',
                currentPrice: 210,
                originalPrice: 350,
            },
            
        ])

    const { setActiveCart } = useContext(noteContext);
    const handleRemoveItem = (indexToRemove) => {
        const updatedWishlist = wishlistItems.filter((_, index) => index !== indexToRemove);
        setWishlistItems(updatedWishlist);
    };

    const handleClearWishlist = () => {
        setWishlistItems([]);
    }
    const calculateTotalPrice = () => {
        return wishlistItems.reduce((total, item) => total + item.currentPrice, 0);
    };

    const [quantity, setQuantity] = useState(1);

    const handleIncrease = () => {
        setQuantity(quantity + 1);
    };

    const handleDecrease = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    useEffect(() => {
        setActiveCart(true)
    }, [])
    return (
        <div>
            <Layout>
                <BreadCrum currentPageName="Cart" />
                <div>
                    {/* <div className='wishlist-header-container'>
                        <span className='home-txt'>Home /</span>
                        <span className='sunflower-oil'> Cart</span>
                    </div> */}

                    <div className='wish-main-container'>
                        <div className='wish-sub-main-container'>
                            <div className='cart-container'>
                                <p className='wishlist-txt'>my cart:</p>
                                <p className='clear-all' onClick={handleClearWishlist}>Clear Cart</p>
                            </div>

                            <div className='subtotal-checkout'>
                                <div>  
                                <div className='cart-items'> 
                                <span className='cart-subtotal'>subtotal</span>
                                <span className='cart-item-length'> ({wishlistItems.length} items)</span> 
                                <span className='cart-rupee'> : ₹{calculateTotalPrice()}</span>
                                </div>
                                </div>
                                <div >
                                    <a
                                        href={pagePaths.shippingDetailsPage}
                                        style={{
                                            textDecoration: "none"
                                        }}
                                    >
                                        <button className='checkout-btn-container'>Checkout</button>
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className='cart-container-heading'>
                                    <p className='product-txt'>Product</p>
                                    <div className='wishlist-container-heading-rightisde'>
                                        <p>quantity</p>
                                        <p>amount</p>
                                        <p>Delete</p>
                                    </div>
                                </div>
                                {wishlistItems.map((item, index) => (
                                    <div key={index} className='cart-container-contents'>
                                        <div className='cart-container2'>
                                            <div className='cart-img-container'>
                                                <img src={item.imageSrc} alt="no image" className='cart-img' />
                                            </div>
                                            <div className='cart-content'>
                                                <p className='cart-selected-items'>{item.name} {item.oilquantity}</p>
                                                <div className='cart-multi-images'>
                                                    <span>
                                                        {Array.from({ length: 5 }).map((_, starIndex) => (
                                                            <img key={starIndex} src={filledstar} alt='alt' className="cart-filled-images1" />
                                                        ))}
                                                    </span>
                                                </div>
                                                <div className='wish-rates'>
                                                    <span className='cart-rates-txt'>₹ {item.currentPrice}</span>
                                                    <span className='cart-rates-txt-cancel'>₹ {item.originalPrice}</span>
                                                </div>

                                                <div className='cart-addtocart-btn'>
                                                    <button className='cart-add-to-cart-btn'>
                                                        {item.oilquantity}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='cart-increase-decrease-btn'>
                                            <span className='cart-decrement' onClick={handleDecrease}>-</span > {quantity} <span className='cart-increamnet' onClick={handleIncrease}>+</span>
                                        </div>
                                        <div className="addtocart-item-amount-container"><span className='addtocart-item-amout'>₹ {item.currentPrice}</span></div>
                                        <div className='cart-cancellation-cross-icon' onClick={() => handleRemoveItem(index)}>
                                            <img src={deleteicon} alt='alt' className='delete-icon-add-to-cart' />
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}
