import React from "react";
import "../styles/headingHeader.css";
import TrendingHeaderDash from "../svg/trendingProductDash.svg";

const widthOutput = window.screen.width;
const trendingHeader = (1.8 / 100) * widthOutput;
const trendingHeaderStyle = {
    fontSize: `${trendingHeader}px`,
    fontWeight: 800
}

export const HeadingHeader = (props) => {
    const {title} = props
    return (
        <div className="mainDiv_HeadingHeader">
            <div className="headerDiv_HeadingHeader">
                <h1 style={trendingHeaderStyle}><img src={TrendingHeaderDash} /><span>{title} </span> <img src={TrendingHeaderDash} /></h1>
            </div>
        </div>
    )
}