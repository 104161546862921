import React from "react";
import "../styles/BreadCrum.css";

const widthOutPut = window.screen.width;
const breadCrumFontSize = (1 / 100) * widthOutPut;

const mainDIvStyls = {
    fontSize: `${breadCrumFontSize}px`
}

const BreadCrum = (props) => {
    const { currentPageName, previousPage,currentPage1 } = props
    return (<>
        <div style={mainDIvStyls} className="mainDiv_BreadCrum">
            Home&nbsp;  /
            <h1 style={{
                fontSize: `${breadCrumFontSize}px`,
                color: "#848484",
                fontFamily: "Poppins-Regular",
                fontWeight: 100
            }}>{previousPage}</h1>&nbsp;&nbsp;
            <h1 style={{
                fontSize: `${breadCrumFontSize}px`,
                color: "#848484",
                fontFamily: "Poppins-Regular",
                fontWeight: 100
            }}>
                {previousPage ? "/" : null}
            </h1>
            <h1 style={{
                fontSize: `${breadCrumFontSize}px`,
                color: `${currentPage1 ? "#848484" : "#000"}`,
                fontFamily: "Poppins-Regular",
                fontWeight: 100
            }}>&nbsp;&nbsp;{currentPageName}</h1>&nbsp;&nbsp;
            <h1 style={{
                fontSize: `${breadCrumFontSize}px`,
                color: "#848484",
                fontFamily: "Poppins-Regular",
                fontWeight: 100
            }}>
                {currentPage1 ? "/" : null}
            </h1>
            <h1 style={{
                fontSize: `${breadCrumFontSize}px`,
                color: `${currentPageName ? "#000" : "#848484"}`,
                fontFamily: "Poppins-Regular",
                fontWeight: 100
            }}>&nbsp;&nbsp;{currentPage1}</h1>
        </div>
    </>)
}
export default BreadCrum;