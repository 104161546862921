import React from "react";
import "../styles/faq.css";
import Layout from "../component/layout";
import BreadCrum from "../component/BreadCrum";

const widthOutput = window.screen.width;
const headerJHeadingFontSize = (2.2 / 100) * widthOutput;
const titleHeaderFontSize = (1.6 / 100) * widthOutput;

const headerHeadingStyles = {
    fontSize: `${headerJHeadingFontSize}px`
}

export const FAQ = () => {
    return (
        <Layout>
            <BreadCrum currentPageName="FAQ’s" />
            <div className="mainDiv_FAQ">
                <div className="innerMainDiv_FAQ">
                    <div className="headerWithCOntentMainDiv_FAQ">
                        <div className="headerHeading_FAQ">
                            <h1 style={headerHeadingStyles}>FAQ’s</h1>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_FAQ">
                        <div className="termsOfUseTitleMainDiv_FAQ">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>How sure am I that the products delivered will be fresh?</h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>All items delivered by us come with a manufacturing date& an expiry date. If you notice any damage to the packaging, wrong productshipped in the order, at the time of delivery, you can request return throughour e-commerce website.
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_FAQ">
                        <div className="termsOfUseTitleMainDiv_FAQ">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>What if I am not home at the time of order delivery? 
                            </h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>Our logistics vendors,will make a total of 2 attempts to deliver at your given delivery address. Soif you miss your delivery on first attempt you can always grab it at second.
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_FAQ">
                        <div className="termsOfUseTitleMainDiv_FAQ">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>If I am not home, can anyone else receive the order? 
                            </h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>In case the person who placed the order is not available at the delivery address, any person available at the delivery address(residential or otherwise) can receive the Products. This will be considered as order delivered.
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_FAQ">
                        <div className="termsOfUseTitleMainDiv_FAQ">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>Do you deliver to all cities?
                            </h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>We currently deliver within the Mysore within3-8hr (Same day delivery If order placed before 4pm)
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_FAQ">
                        <div className="termsOfUseTitleMainDiv_FAQ">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>Can I cancel my order after placing it?
                            </h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>The order once placed cannot be cancelled after it has been shipped. In case of cancellations, the amount will be refunded to you inthe form of coupon code or store credit within 7 to 10 business days.
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_FAQ">
                        <div className="termsOfUseTitleMainDiv_FAQ">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>Once the order is made, can the order be cancelled?
                            </h1>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`,
                                paddingTop:"2rem"
                            }}>The order placed by the Customer /Guest can be cancelledonly before the order is shipped Orde . The amount will be refunded to you inthe form of coupon code or store credit within 15 days.
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>For orders that have already been shipped, there will beno refunds made except for damage of packaging, and wrong product shipped. Whatabout any hidden costs (sales tax, octroi etc.)?
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>VAT is charged on the order which you will have a chanceto review in your Shopping Cart. We donot charge any other addtional charges.
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}