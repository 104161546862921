import React, { useState } from "react";
import "../styles/shippingDetailsPage.css";
import Layout from "../component/layout";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Badge, Box, TextField } from "@material-ui/core";
import Img1 from "../images/img1.png";
import CrossIcon from "../svg/cross.svg";
import BackArrow from "../svg/backArrow.svg";
import BreadCrum from "../component/BreadCrum";

const widthOutput = window.screen.width;
const firstBreadcrumNumberFontsize = (0.9 / 100) * widthOutput;
const number_titleFontSize = (1 / 100) * widthOutput;
const boxNumberSize = (2.5 / 100) * widthOutput;
const contentHeaderFOntSize = (1.6 / 100) * widthOutput;
const orderListPopupTitleFontSize = (1.3 / 100) * widthOutput;
const orderDetailsTrackOrderFontSize = (1.3 / 100) * widthOutput;

const breadCrumNumberStyles = {
    width: `${boxNumberSize}px`,
    height: `${boxNumberSize}px`,
    fontSize: `${number_titleFontSize}px`
}
const orderProductListPopup = [
    {
        img: Img1,
        title: "Fresh sunflower oil 990ml",
        size: "990ml",
        price: 210,
        actualPrice: 350,
        numberOfQuantity: 5,
    },
    {
        img: Img1,
        title: "Fresh sunflower oil 990ml",
        size: "990ml",
        price: 210,
        actualPrice: 350,
        numberOfQuantity: 2,
    },
    {
        img: Img1,
        title: "Fresh sunflower oil 990ml",
        size: "990ml",
        price: 210,
        actualPrice: 350,
        numberOfQuantity: 1,
    },
    {
        img: Img1,
        title: "Fresh sunflower oil 990ml",
        size: "990ml",
        price: 210,
        actualPrice: 350,
        numberOfQuantity: 1,
    },
    {
        img: Img1,
        title: "Fresh sunflower oil 990ml",
        size: "990ml",
        price: 210,
        actualPrice: 350,
        numberOfQuantity: 19,
    },
]

export const ShippingDetails = () => {
    const [loadMoreProduct, setLoadMoreProduct] = useState(3)
    const [viewLesProduct, setViewLessProduct] = useState(false)
    const [activeBillingAddress, setActiveBillingAddredd] = useState(false)
    return (
        <Layout>
            <BreadCrum currentPageName="Shipping Details" />
            <div className="MainDiv_ShippingDetailsPage">
                <div className="innerBreadCrumMainDIv_ShippingDetailsPage">
                    <div className="breadCrumCircleMainDiv_ShippingDetailsPage">
                        <div className="firstBreadCrumMainDIv_ShippingDetailsPage">
                            <h1 style={breadCrumNumberStyles}>
                                1
                            </h1>
                        </div>
                        <div className="dividerAfterFirestBreadCrum_ShippingDetailsPage">

                        </div>
                        <div className="secondBreadCrumMainDIv_ShippingDetailsPage">
                            <h1 style={breadCrumNumberStyles}>
                                2
                            </h1>
                        </div>
                    </div>
                    <div className="breadcrumTitle_ShippingDetailsPage">
                        <h1 style={{
                            fontSize: `${firstBreadcrumNumberFontsize}px`
                        }}>shipping details</h1>
                        <h2 style={{
                            fontSize: `${firstBreadcrumNumberFontsize}px`
                        }}>payment</h2>
                    </div>
                </div>
                <div className="mainContentMainDIv_ShippingDetailsPage">
                    <div className="leftMainContentMainDiv_ShippingDetailsPage">
                        <div className="innerleftMainContentMainDiv_ShippingDetailsPage">
                            <div className="headerLeftContentMainDiv_ShippingDetailsPage">
                                <div className="leftHeaderContent_ShippingDetailsPage">
                                    <h1 style={{
                                        fontSize: `${contentHeaderFOntSize}px`
                                    }}>contact</h1>
                                </div>
                                {/* <div className="RightHeaderContent_ShippingDetailsPage">
                                    <h2 style={{
                                        fontSize: `${contentHeaderFOntSize * 0.6}px`
                                    }}>have an account? <span>log in</span></h2>
                                </div> */}
                            </div>
                            <div className="leftContentInnerMainDIv_ShippingDetailsPage">
                                <TextField
                                    style={{
                                        border: "1px solid #AAAAAA",
                                        borderRadius: "4px"
                                    }}
                                    id="standard-helperText"
                                    label="Email or Phone"
                                    placeholder="Enter Here"
                                    // helperText="Some important text"
                                    variant="outlined"
                                    fullWidth={true}
                                />
                            </div>
                            <div className="deliveryMainDIvLeftContent_ShippingDetailsPage">
                                <h1 style={{
                                    fontSize: `${contentHeaderFOntSize}px`
                                }}>delivery</h1>
                                <Box style={{
                                    border: "1px solid #AAAAAA",
                                    borderRadius: "4px"
                                }} sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            // value={age}
                                            label="Age"
                                        // onChange={handleChange}
                                        >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <div className="leftNameContentInnerMainDIv_ShippingDetailsPage">
                                <div style={{
                                    width: "48%"
                                }}>
                                    <Box style={{
                                        border: "1px solid #AAAAAA",
                                        borderRadius: "4px"
                                    }}>
                                        <TextField
                                            id="standard-helperText"
                                            label="First Name"
                                            placeholder="Enter Here"
                                            // helperText="Some important text"
                                            variant="outlined"
                                        // fullWidth={true}
                                        // maxLength="90"

                                        />
                                    </Box>
                                </div>
                                <div style={{
                                    width: "48%"
                                }}>
                                    <Box style={{
                                        border: "1px solid #AAAAAA",
                                        borderRadius: "4px"
                                    }}>
                                        <TextField
                                            id="standard-helperText"
                                            label="Last Name"
                                            placeholder="Enter Here"
                                            // helperText="Some important text"
                                            variant="outlined"
                                        // fullWidth={true}
                                        // maxLength="90"

                                        />
                                    </Box>
                                </div>
                            </div>
                            <div style={{
                                paddingTop: "3%"
                            }} className="leftContentInnerMainDIv_ShippingDetailsPage">
                                <TextField
                                    style={{
                                        border: "1px solid #AAAAAA",
                                        borderRadius: "4px"
                                    }}
                                    id="standard-helperText"
                                    label="Address"
                                    // defaultValue="Enter Here"
                                    // helperText="Some important text"
                                    variant="outlined"
                                    fullWidth={true}
                                    placeholder="Enter Here"
                                />
                            </div>
                            <div style={{
                                paddingTop: "3%"
                            }} className="leftContentInnerMainDIv_ShippingDetailsPage">
                                <div style={{
                                    width: "32%"
                                }}>
                                    <Box style={{
                                        border: "1px solid #AAAAAA",
                                        borderRadius: "4px"
                                    }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">City</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                // value={age}
                                                label="Age"
                                            // onChange={handleChange}
                                            >
                                                <MenuItem value="blsjxdks">blsjxdks</MenuItem>
                                                <MenuItem value="hsgcvdjsyd">hsgcvdjsyd</MenuItem>
                                                <MenuItem value="shgcvfgsc">shgcvfgsc</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                                <div style={{
                                    width: "32%"
                                }}>
                                    <Box style={{
                                        border: "1px solid #AAAAAA",
                                        borderRadius: "4px"
                                    }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">State</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                // value={age}
                                                label="State"
                                            // onChange={handleChange}
                                            >
                                                <MenuItem value="blsjxdks">blsjxdks</MenuItem>
                                                <MenuItem value="hsgcvdjsyd">hsgcvdjsyd</MenuItem>
                                                <MenuItem value="shgcvfgsc">shgcvfgsc</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                                <div style={{
                                    width: "32%"
                                }}>
                                    <Box style={{
                                        border: "1px solid #AAAAAA",
                                        borderRadius: "4px"
                                    }}>
                                        <TextField
                                            id="standard-helperText"
                                            label="Pincode"
                                            placeholder="Enter Here"
                                            // helperText="Some important text"
                                            variant="outlined"
                                        // fullWidth={true}
                                        // maxLength="90"

                                        />
                                    </Box>
                                </div>

                            </div>
                            <div className="shippingBillingMainDIv_ShippingDetailsPage">
                                <h1 style={{
                                    fontSize: `${contentHeaderFOntSize}px`
                                }}>billing address</h1>
                                {/* <div onClick={() => setActiveBillingAddredd(false)} className="shippingAddressMainDiv_ShippingDetailsPage"> */}
                                {/* <input id="shippingAddress" type="radio" name="Address" value="shippingAddress" /> */}
                                <label
                                    onClick={() => setActiveBillingAddredd(false)}
                                    className="shippingAddressMainDiv_ShippingDetailsPage"
                                    style={{
                                        fontSize: `${number_titleFontSize * 1.1}px`
                                    }} for="shippingAddress"> <input id="shippingAddress" type="radio" name="Address" value="shippingAddress" /> same as shipping address</label>
                                {/* </div> */}
                                {/* <div id="billingAddress" onClick={() => setActiveBillingAddredd(true)} className="billingAddressMainDiv_ShippingDetailsPage"> */}

                                <label
                                    onClick={() => setActiveBillingAddredd(true)}
                                    className="billingAddressMainDiv_ShippingDetailsPage" style={{
                                        fontSize: `${number_titleFontSize * 1.1}px`
                                    }} for="billingAddress"><input id="billingAddress" type="radio" name="Address" value="billingAddress" /> Add a different billing addredd</label>
                                {/* </div> */}
                                {
                                    activeBillingAddress ?
                                        <div>
                                            <div style={{
                                                paddingTop: "3%"
                                            }} className="leftContentInnerMainDIv_ShippingDetailsPage">
                                                <Box style={{
                                                    border: "1px solid #AAAAAA",
                                                    borderRadius: "4px"
                                                }} sx={{ minWidth: 120 }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            // value={age}
                                                            label="Age"
                                                        // onChange={handleChange}
                                                        >
                                                            <MenuItem value={10}>Ten</MenuItem>
                                                            <MenuItem value={20}>Twenty</MenuItem>
                                                            <MenuItem value={30}>Thirty</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </div>
                                            <div className="leftNameContentInnerMainDIv_ShippingDetailsPage">
                                                <div style={{
                                                    width: "48%"
                                                }}>
                                                    <Box style={{
                                                        border: "1px solid #AAAAAA",
                                                        borderRadius: "4px"
                                                    }}>
                                                        <TextField
                                                            id="standard-helperText"
                                                            label="First Name"
                                                            placeholder="Enter Here"
                                                            // helperText="Some important text"
                                                            variant="outlined"
                                                        // fullWidth={true}
                                                        // maxLength="90"

                                                        />
                                                    </Box>
                                                </div>
                                                <div style={{
                                                    width: "48%"
                                                }}>
                                                    <Box style={{
                                                        border: "1px solid #AAAAAA",
                                                        borderRadius: "4px"
                                                    }}>
                                                        <TextField
                                                            id="standard-helperText"
                                                            label="Last Name"
                                                            placeholder="Enter Here"
                                                            // helperText="Some important text"
                                                            variant="outlined"
                                                        // fullWidth={true}
                                                        // maxLength="90"

                                                        />
                                                    </Box>
                                                </div>
                                            </div>
                                            <div style={{
                                                paddingTop: "3%"
                                            }} className="leftContentInnerMainDIv_ShippingDetailsPage">
                                                <TextField
                                                    style={{
                                                        border: "1px solid #AAAAAA",
                                                        borderRadius: "4px"
                                                    }}
                                                    id="standard-helperText"
                                                    label="Address"
                                                    // defaultValue="Enter Here"
                                                    // helperText="Some important text"
                                                    variant="outlined"
                                                    fullWidth={true}
                                                    placeholder="Enter Here"
                                                />
                                            </div>
                                            <div style={{
                                                paddingTop: "3%"
                                            }} className="leftContentInnerMainDIv_ShippingDetailsPage">
                                                <div style={{
                                                    width: "32%"
                                                }}>
                                                    <Box style={{
                                                        border: "1px solid #AAAAAA",
                                                        borderRadius: "4px"
                                                    }}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">City</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                // value={age}
                                                                label="Age"
                                                            // onChange={handleChange}
                                                            >
                                                                <MenuItem value="blsjxdks">blsjxdks</MenuItem>
                                                                <MenuItem value="hsgcvdjsyd">hsgcvdjsyd</MenuItem>
                                                                <MenuItem value="shgcvfgsc">shgcvfgsc</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </div>
                                                <div style={{
                                                    width: "32%"
                                                }}>
                                                    <Box style={{
                                                        border: "1px solid #AAAAAA",
                                                        borderRadius: "4px"
                                                    }}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">State</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                // value={age}
                                                                label="State"
                                                            // onChange={handleChange}
                                                            >
                                                                <MenuItem value="blsjxdks">blsjxdks</MenuItem>
                                                                <MenuItem value="hsgcvdjsyd">hsgcvdjsyd</MenuItem>
                                                                <MenuItem value="shgcvfgsc">shgcvfgsc</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </div>
                                                <div style={{
                                                    width: "32%"
                                                }}>
                                                    <Box style={{
                                                        border: "1px solid #AAAAAA",
                                                        borderRadius: "4px"
                                                    }}>
                                                        <TextField
                                                            id="standard-helperText"
                                                            label="Pincode"
                                                            placeholder="Enter Here"
                                                            // helperText="Some important text"
                                                            variant="outlined"
                                                        // fullWidth={true}
                                                        // maxLength="90"

                                                        />
                                                    </Box>
                                                </div>

                                            </div>
                                        </div> : null
                                }

                            </div>
                        </div>
                    </div>
                    <div className="RightMainContentMainDiv_ShippingDetailsPage">
                        {
                            orderProductListPopup?.slice(0, 3)?.map((productDetails, id) => {
                                return (<>
                                    <div className="viewOrderCardDetailsLeftSectionMyOrder_ShippingDetailsPage">
                                        <div className="viewOrderleftInnerCardDetailsMyOrder_ShippingDetailsPage">
                                            <div className="viewOrderImageLeftInnerCardDetailsMyOrder_ShippingDetailsPage">
                                                <Badge color="secondary" badgeContent={productDetails.numberOfQuantity}>
                                                    <img src={productDetails.img} />
                                                </Badge>
                                            </div>
                                            <div className="viewOrderContentLeftInnerCardDetailsMyOrder_ShippingDetailsPage">
                                                <h1 style={{
                                                    fontSize: `${orderListPopupTitleFontSize * 0.9}px`
                                                }}>{productDetails.title}</h1>
                                                <h4 style={{
                                                    fontSize: `${orderListPopupTitleFontSize * 0.7}px`
                                                }}>{productDetails.size}</h4>
                                                <h2 style={{
                                                    fontSize: `${orderListPopupTitleFontSize * 0.8}px`
                                                }}>₹ {productDetails.price} <span>&nbsp;₹ {productDetails.actualPrice}</span></h2>
                                            </div>
                                        </div>
                                        <div className="viewOrderCardDetailsRightSectionMyOrder_ShippingDetailsPage">
                                            <h2 style={{
                                                fontSize: `${orderListPopupTitleFontSize * 0.8}px`
                                            }}>₹ {productDetails.price}</h2>
                                        </div>
                                    </div>
                                </>)
                            })
                        }
                        <div
                            onClick={() => setViewLessProduct(true)}
                            className="loadMoreViewCardDetails_ShippingDetailsPage">
                            <h4 style={{
                                fontSize: `${orderListPopupTitleFontSize * 0.7}px`
                            }} >
                                view {orderProductListPopup.length - 3} more &nbsp; <img src={BackArrow} />
                            </h4>
                        </div>
                        <div className="discountCouponMainDiv_ShippingDetailsPage">
                            <div className="discountInputDiv_ShippingDetailsPage">
                                <TextField
                                    id="standard-helperText"
                                    label="Discount Coupon Code"
                                    placeholder="Enter Here"
                                    // helperText="Some important text"
                                    variant="outlined"
                                    fullWidth={true}
                                />
                            </div>
                            <div className="applyBtnDiv_ShippingDetailsPage">
                                <button style={{
                                    fontSize: `${orderDetailsTrackOrderFontSize * 0.7}px`
                                }}>Apply</button>
                            </div>
                        </div>
                        <div className="subTotalMainDIv_ShippingDetailsPage">
                            <h1 style={{
                                fontSize: `${orderDetailsTrackOrderFontSize}px`
                            }}>subtotal</h1>
                            <h1 style={{
                                fontSize: `${orderDetailsTrackOrderFontSize}px`
                            }}>₹ 1440</h1>
                        </div>
                        <div className="proceedToPayBtn_ShippingDetailsPage">
                            <button style={{
                                fontSize: `${orderDetailsTrackOrderFontSize * 0.9}px`
                            }}>Procced to pay</button>
                        </div>
                    </div>

                </div>
                {
                    viewLesProduct ?
                        <div className="quickViewMainDivTrackOrder_MyOrder_AccountSection">
                            <div className="innerOrderDetailsTrackOrder_AccountSection">
                                <div className="headerOrderDetailsTrackOrder_AccountSection">
                                    <div className="upperHeaderOrderDetailsTrackOrder_AccountSection">
                                        <h1 style={{
                                            fontSize: `${orderDetailsTrackOrderFontSize}px`
                                        }}>Order products <span style={{
                                            fontSize: `${orderDetailsTrackOrderFontSize * 0.8}px`
                                        }}>&nbsp;(10 items)</span></h1>
                                        <h3 style={{
                                            fontSize: `${orderDetailsTrackOrderFontSize * 0.8}px`
                                        }}>you can see here all the products you ordered</h3>
                                    </div>
                                    <div className="lowerHeaderOrderDetailsTrackOrder_AccountSection">
                                        <img onClick={() => setViewLessProduct(false)} src={CrossIcon} />
                                    </div>
                                </div>
                                <div className="trackOrderProductDetailsListMainDIvMyOrder_AccountSection">
                                    {
                                        orderProductListPopup?.map((productDetails, id) => {
                                            return (<>
                                                <div className="trackOrderProductDetailsListMyOrder_AccountSection">
                                                    <div className="trackOrderOrderDetailsCardMyOrder_AccountSection">
                                                        <div className="cardDetailsLeftSectionMyOrder_AccountSection">
                                                            <div className="leftInnerCardDetailsMyOrder_AccouontSection">
                                                                <div className="ImageLeftInnerCardDetailsMyOrder_AccountSection">
                                                                    <Badge color="secondary" badgeContent={productDetails.numberOfQuantity}>
                                                                        <img src={productDetails.img} />
                                                                    </Badge>
                                                                </div>
                                                                <div className="contentLeftInnerCardDetailsMyOrder_AccountSection">
                                                                    <h1 style={{
                                                                        fontSize: `${orderListPopupTitleFontSize}px`
                                                                    }}>{productDetails.title}</h1>
                                                                    <h4 style={{
                                                                        fontSize: `${orderListPopupTitleFontSize * 0.6}px`
                                                                    }}>{productDetails.size}</h4>
                                                                    <h2 style={{
                                                                        fontSize: `${orderListPopupTitleFontSize}px`
                                                                    }}>₹ {productDetails.price} <span>&nbsp;₹ {productDetails.actualPrice}</span></h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="cardDetailsRightSectionMyOrder_AccountSection">
                                                            <h2 style={{
                                                                fontSize: `${orderListPopupTitleFontSize}px`
                                                            }}>₹ {productDetails.price}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)
                                        })
                                    }
                                </div>

                            </div>
                        </div>
                        : null
                }
            </div>
        </Layout>
    )
}