import Layout from '../../component/layout';
import '../../styles/Aboutus/Aboutus.css';
import React from 'react';
import privacypolicyimg from '../../images/PrivacyPolicy/privacypolicyimg.png';
import redline from '../../svg/PrivacyPolicy/redline.svg';
import BreadCrum from '../../component/BreadCrum';

export default function Aboutus() {
    return (
        <div>
            <Layout>
                <BreadCrum currentPageName="About us" />
                <div>
                    {/* <div className='wishlist-header-container'>
                        <span className='home-txt'>Home</span>
                        <span>/</span>
                        <span className='sunflower-oil'> About us</span>
                    </div> */}

                    <div className='pp-main-container'>
                        <div className='pp-sub-main-container'>
                            <div className='top-header'>
                                <span><img src={redline} alt='alt' />
                                </span><span className='about-us-heading'> About us</span>
                                <span><img src={redline} alt='alt' /></span>
                            </div>
                            <p className='aboutus-info'>India’s first Sunflower oil processed without the use of Caustic and Sulphuric acid, produced from the highest</p>
                            <p className='aboutus-info'> quality seeds using wind energy to offset the carbon foot print.</p>
                            <div>
                                <div className='bg-img-container'>
                                    <img src={privacypolicyimg} alt='alt' className='bg-img' />
                                    <div className='pp-middle-container'>
                                        <div className='txt-container'>
                                            <p className='mid-txt'>super healthy cooking oils</p>
                                            <p className='mid-txt1'>for everyone’s indulgence</p>
                                        </div>

                                        <div className='shop-now-btn-container'>
                                            <button className='shop-now-btn'>Shop Now</button>
                                        </div>
                                    </div>
                                </div>

                                <div className='pp-pessage'>
                                    <p className='pessage1'>
                                        We at Masoom group have consulted with experts in the field from U.S.A in relation with the Sunflower association of U.S.A,
                                        who have helped improve the standard of the process to those of the European level. We use the latest, state of the art
                                        equipment from all over the world to produce products that are unique. Our units have fully-fledged labs
                                        that allows it to carry out research and development programs with the help of highly qualified personnel and resources.
                                    </p>

                                    <p className='pessage1'>
                                        We have done extensive work in finding ways to reduce the effluents that may be produced during the refining process and strive
                                        towards making products that are healthy for the consumers and help us live in a world that will last. The labs have highly
                                        skilled chemists that work around the clock to maintain a uniform quality of oil. All of this has led to a product that is
                                        produced using green power, with no caustic or phosphoric acid, a product free from preservatives and one that retains the
                                        natural goodness of oil, just the way nature intended it to be.The group produces more than 12 time the power it consumes
                                        from wind turbines.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}
