import React from "react";
import { Toolbar } from "../container/toolbar";
import { Footer } from "../container/footer";

const Layout = ({ children }) => {
    return (<>
        <Toolbar />
        {children}
        <Footer />
    </>)
}
export default Layout;