import React, { useEffect, useState } from "react";
import "../styles/contactUs.css";
import Layout from "../component/layout";
import { HeadingHeader } from "./headingHeader";
import PhoneIcon from "../svg/phoneIcon.svg";
import LocationIcon from "../svg/locationIcon.svg";
import EmailIcon from "../svg/emailContactIcon.svg";
import BreadCrum from "../component/BreadCrum";


const widthOutput = window.screen.width;
const contactTitleFontSize = (1.2 / 100) * widthOutput;
const contactDetailsFOntSize = (1 / 100) * widthOutput;
const fastNameFontSize = (1.3 / 100) * widthOutput;

const phoneTitleStyle = {
    fontSize: `${contactTitleFontSize}px`
}
const phoneDetailsStyles = {
    fontSize: `${contactDetailsFOntSize}px`,

}
const firstNameStyle = {
    fontSize: `${fastNameFontSize}px`
}

export const ContactUs = () => {
   

    return (
        <Layout>
            <BreadCrum currentPageName="Contact Us" />
            <div className="mainDiv_ContactUs">
                <div className="headingDiv_ContactUS">
                    <HeadingHeader title="Contact Us" />
                </div>
                <div className="contactDetailsMainDiv_ContactUs">
                    <div className="contactDetailsInnerMainDiv_ContactUs">
                        <div className="contactPhoneMainDIv_ContactUS">
                            <div className="phoneIconDiv_ContactUs">
                                <img src={PhoneIcon} />
                            </div>
                            <div 
                            className="phoneTitleDiv_ContactDIv-contactUs"
                            style={{
                               
                            }}>
                                <div className="phoneTitleDiv_ContactDIv">
                                    <h1 style={phoneTitleStyle}>phone</h1>
                                </div>
                                <div className="detailsPhoneDiv_COntactUS">
                                    <h2 style={phoneDetailsStyles}>+91 7026006008</h2>
                                </div>
                            </div>
                        </div>
                        <div className="contactPhoneMainDIv_ContactUS addressMianDiv_ContactUs">
                            <div className="phoneIconDiv_ContactUs">
                                <img src={LocationIcon} />
                            </div>
                            <div className="phoneTitleDiv_ContactDIv">
                                <h1 style={phoneTitleStyle}>address</h1>
                            </div>
                            <div className="detailsPhoneDiv_COntactUS address_ContactUs">
                                <h2 style={phoneDetailsStyles}>M K agrotech pvt ltd
                                    Kaveri layout, M.B. Road, Srirangapatna, Karnataka 571438</h2>
                            </div>
                        </div>
                        <div className="contactPhoneMainDIv_ContactUS">
                            <div className="phoneIconDiv_ContactUs">
                                <img src={EmailIcon} />
                            </div>
                            <div style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column"
                            }}>
                                <div className="phoneTitleDiv_ContactDIv">
                                    <h1 style={phoneTitleStyle}>email</h1>
                                </div>
                                <div className="detailsPhoneDiv_COntactUS email_ContactUs">
                                    <h2 style={phoneDetailsStyles}>hello@sunpure.in</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sendMessegeMainDiv_ContactUs">
                    <div className="sendMessageInnerMainDiv_ContactUs">
                        <div className="firstNameSendMassage_ContactUS">
                            <div style={{
                                display: "flex",
                                justifyContent: "start",
                                width: "80%",
                                padding: "1.5rem 1.5rem 1rem 0",
                            }}>
                                <h2 style={firstNameStyle}>first name</h2>
                            </div>
                            <input
                                type="text"
                                placeholder="Mohit Chauhan"
                            />
                        </div>
                        <div className="firstNameSendMassage_ContactUS">
                            <div style={{
                                display: "flex",
                                justifyContent: "start",
                                width: "80%",
                                padding: "1.5rem 1.5rem 1rem 0",
                            }}>
                                <h2 style={firstNameStyle}>Phone No.</h2>
                            </div>
                            <input
                                type="number"
                                placeholder="Enter Here"
                            />
                        </div>
                        <div className="firstNameSendMassage_ContactUS">
                            <div style={{
                                display: "flex",
                                justifyContent: "start",
                                width: "80%",
                                padding: "1.5rem 1.5rem 1rem 0",
                            }}>
                                <h2 style={firstNameStyle}>Email Id</h2>
                            </div>
                            <input
                                type="number"
                                placeholder="Enter Here"
                            />
                        </div>
                        <div className="firstNameSendMassage_ContactUS">
                            <div style={{
                                display: "flex",
                                justifyContent: "start",
                                width: "80%",
                                padding: "1.5rem 1.5rem 1rem 0",
                            }}>
                                <h2 style={firstNameStyle}>Email Id</h2>
                            </div>
                            <textarea
                                type="number"
                                placeholder="Enter Here"
                            />
                        </div>
                        <div className="sendMessageBtnDiv_ContactUs">
                            <button style={{
                                fontSize: `${fastNameFontSize * 0.9}px`
                            }}>
                                Send Massege
                            </button>
                        </div>
                    </div>

                </div>

            </div>
        </Layout>
    )
}