import React, { useState } from "react";
import "../styles/trendingProduct.css";
import TrendingHeaderDash from "../svg/trendingProductDash.svg";
import DummyImage from "../images/dummyImg.png";
import LockIcon from "../svg/lockIcon.svg";
import WishlistFrameIcon from "../svg/wishlistFram.svg";
import QuickViewIcon from "../svg/quickViewIcon.svg";
import TagSvg from "../svg/tag.svg";
import ReactStars from "react-stars";
import CrossMarkIcon from "../svg/cross.svg";
import Img1 from "../images/img1.png";
import Img2 from "../images/img2.png";
import WishListProductIcon from "../svg/wishlistProductIcon.svg"
import { pagePaths } from "../utils/constant";
import { HeadingHeader } from "./headingHeader";


const widthOutput = window.screen.width;
const trendingHeader = (1.8 / 100) * widthOutput;
const cardTitleFontSize = (0.9 / 100) * widthOutput;
const cardHeight = (17.5 / 100) * widthOutput;
const cardPrice = (1 / 100) * widthOutput;
const aadToCartFontSize = (0.8 / 100) * widthOutput;
const addToCartWidth = (8 / 100) * widthOutput;
const addToCartHeight = (2.5 / 100) * widthOutput;
const priceDetailsTitleFontSize = (1.4 / 100) * widthOutput;
const quantityFontSize = (1.2 / 100) * widthOutput;
const quantityIncrDecrFontSize = (1.4 / 100) * widthOutput;
const btnFontSize = (1 / 100) * widthOutput;
const viewDetailsFontSize = (1 / 100) * widthOutput

const trendingHeaderStyle = {
    fontSize: `${trendingHeader}px`,
    fontWeight: 800
}
const cardTitleStyle = {
    fontSize: `${cardTitleFontSize}px`,
    color: "#525252",
    lineHeight: 2
}
const cardDetailsStyles = {
    fontSize: `${cardPrice}px`,
    color: "#222222",
    lineHeight: 2,
    display: "flex",
}
const cardDetailsPricesStyle = {
    fontSize: `${cardPrice * 1.5}px`,
    color: "#222222",
    lineHeight: 2,
    display: "flex"
}
const addToCartButtonStyle = {
    fontSize: `${aadToCartFontSize}px`,
    width: `${addToCartWidth}px`,
    height: `${addToCartHeight}px`,
    color: "#464646",
    fontWeight: 600
}
const contentDetailsTitle = {
    fontSize: `${priceDetailsTitleFontSize}px`,
    fontWeight: 600,
    color: "black",
    borderBottom: "1px solid #BCBCBC",
    paddingBottom: "0.8rem"
}

const productCard = [
    {
        img: Img1,
        title: "Fresh Sunflower Oil 990ml",
        rating: 5.0,
        prize: 220,
        realPrize: 500,
        sizes: [
            {
                size: "900ml",
            },
            {
                size: "1900ml",
            },
            {
                size: "200ml",
            },
            {
                size: "300ml",
            },
        ],
        productDetailsImgs: [
            {
                img: Img1,
            },
            {
                img: Img2,
            },
            {
                img: Img1,
            },
            {
                img: Img2,
            },
        ]
    },
    {
        img: Img2,
        title: "Fresh Oil 990ml",
        rating: 5.0,
        prize: 220,
        realPrize: 500,
        sizes: [
            {
                size: "900ml",
            },
            {
                size: "1900ml",
            },
            {
                size: "200ml",
            },
            {
                size: "300ml",
            },
        ],
        productDetailsImgs: [
            {
                img: Img2,
            },
            {
                img: Img1,
            },
            {
                img: Img2,
            },
            {
                img: Img1,
            },
        ]
    },
    {
        img: Img1,
        title: "Fresh Sunflower Oil",
        rating: 5.0,
        prize: 220,
        realPrize: 500,
        sizes: [
            {
                size: "900ml",
            },
            {
                size: "1900ml",
            },
            {
                size: "200ml",
            },
            {
                size: "300ml",
            },
        ],
        productDetailsImgs: [
            {
                img: Img1,
            },
            {
                img: Img2,
            },
            {
                img: Img1,
            },
            {
                img: Img2,
            },
        ]
    },
    {
        img: Img2,
        title: " Sunflower Oil 990ml",
        rating: 5.0,
        prize: 220,
        realPrize: 500,
        sizes: [
            {
                size: "900ml",
            },
            {
                size: "1900ml",
            },
            {
                size: "200ml",
            },
            {
                size: "300ml",
            },
        ],
        productDetailsImgs: [
            {
                img: Img2,
            },
            {
                img: Img1,
            },
            {
                img: Img2,
            },
            {
                img: Img1,
            },
        ]
    },
    {
        img: Img1,
        title: "Fresh Sunflower Oil 990ml",
        rating: 2.5,
        prize: 220,
        realPrize: 500,
        sizes: [
            {
                size: "900ml",
            },
            {
                size: "1900ml",
            },
            {
                size: "200ml",
            },
            {
                size: "300ml",
            },
        ],
        productDetailsImgs: [
            {
                img: Img1,
            },
            {
                img: Img2,
            },
            {
                img: Img1,
            },
            {
                img: Img2,
            },
        ]
    },

]

const productDetailsImgs = [
    {
        img: Img1,
    },
    {
        img: Img2,
    },
    {
        img: Img1,
    },
    {
        img: Img2,
    },
]


export const TrendingProduct = () => {
    const [quickView, setQuickView] = useState([])
    const [productZoomImg, setProductZoomImg] = useState([])
    const [activeWishlist, setActiveWishList] = useState()
    const [size, setSize] = useState("")
    const [quantityIncre, setQuantityIncre] = useState(0)
    const [activeQuickView, setActiveQuickView] = useState(false);

    const handleQuickView = (quickViewDetails) => {
        setActiveQuickView(true)
        // if(setActiveQuickView){
        //     document.body.style.overflow = "hidden"
        // }
        // else
        // {
        //     document.body.style.overflow = "auto"
        // }
        setProductZoomImg(quickViewDetails.img)
        setQuickView(quickViewDetails)
    }

    console.log("checking array", quickView, "<<<===>>>", productZoomImg, "<<===>>", quickView.img)

    return (<>
        <div className="mainDiv_TrendingProduct">
            <div className="innerMainDiv_TrendingProduct">
                <div className="innerInnerMainDiv_TrendingProduct">
                    {/* <div className="headerDiv_TrendingProduct">
                        <h1 style={trendingHeaderStyle}><img src={TrendingHeaderDash} />&nbsp; Trending Product &nbsp; <img src={TrendingHeaderDash} /></h1>
                    </div> */}
                    <HeadingHeader title=" Trending Product" />
                    <div className="trendingProductCard_TrendingProduct">
                        {
                            productCard?.map((item, id) => {
                                return (<>
                                    <div className="cardMainDiv_TrendingProduct">
                                        <div style={{
                                            background: "#8080800d",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: `${cardHeight}px`,
                                            position: "relative"
                                        }}
                                            className="cardInnerInnerMainDiv_TrendingProduct"
                                        >
                                            <div style={{
                                                backgroundImage: `url(${item.img})`,
                                                height: `${cardHeight * 0.7}px`,
                                                backgroundSize: "contain",
                                                backgroundRepeat: "no-repeat",
                                                width: "70%"
                                            }}
                                                className="trendingProductCardInnerImg_TrendingProduct"
                                            >

                                            </div>
                                            <div className="hoverActionButton_TrendingProduct">
                                                <img className="wishList_TrendingProduct" src={WishlistFrameIcon} />
                                                <img onClick={() => handleQuickView(item)} className="quickView_TrendingProduct" src={QuickViewIcon} alt="Quick View" />
                                                {/* <div className="wishlistTagDiv_TrendingProduct">
                                                    <img className="tagForWishList" src={TagSvg} />
                                                    <h5 className="tagLineForWishList">Quick view</h5>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="cardDetails_TrenProd_Wrapper">
                                        <div className="cardDetails_TrendingProduct">
                                            <h1 style={cardTitleStyle}>{item.title}</h1>
                                            <ReactStars
                                                count={5}
                                                edit={false}
                                                // onChange={ratingChanged}
                                                value={item.rating}
                                                size={16}
                                                isHalf={true}
                                                activeColor="#FFB100"
                                                color1={'#dddddd'}
                                                className="starComponent"
                                            />
                                            <h3 style={cardDetailsStyles}>₹ {item.prize}&nbsp;&nbsp; <h4>₹ {item.realPrize}</h4></h3>
                                        </div>
                                        <div className="addToCartButton">
                                            <button onClick={() => handleQuickView(item)} style={addToCartButtonStyle}>
                                                <img src={LockIcon} />&nbsp; Add to Cart
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </>)
                            })
                        }
                        {
                            activeQuickView ?

                                <div className="quickViewMainDiv_MyOrder_TrendingProduct">
                                    <div className="quickViewInnerMainDiv_TrendingProduct">
                                        <div className="crossUpperDivQuickView_TrendingProduct">
                                            <img onClick={() => setActiveQuickView(false)} src={CrossMarkIcon} />
                                        </div>
                                        <div className="ContentDetailsQuickView_TrendingProduct">
                                            <div className="contentImgMainDIv_TrendingProduct">
                                                <div className="productDetailZoomImg_TrendingProduct">
                                                    <div style={{
                                                        background: "#F8F8F8",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}>
                                                        <img src={productZoomImg} />
                                                    </div>
                                                    <div>
                                                        <svg style={{
                                                            position: "absolute",
                                                            right: "2%",
                                                            width: "15%",
                                                            top: "-1%"
                                                        }}
                                                            onClick={() => setActiveWishList(!activeWishlist)}
                                                            width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g filter="url(#filter0_d_45_229)">
                                                                <circle cx="33.5" cy="31.5" r="30.5" fill="white" />
                                                            </g>
                                                            <path d="M33.4999 43.5582C33.0838 43.5577 32.6774 43.4358 32.3337 43.2084C27.242 39.8578 25.0373 37.5605 23.8212 36.1242C21.2296 33.0626 19.9889 29.9193 20.0239 26.5154C20.0647 22.6147 23.2931 19.4419 27.2206 19.4419C30.0765 19.4419 32.0545 21.0013 33.2064 22.3001C33.2429 22.3408 33.288 22.3734 33.3387 22.3958C33.3893 22.4182 33.4443 22.4298 33.4999 22.4298C33.5556 22.4298 33.6106 22.4182 33.6612 22.3958C33.7119 22.3734 33.7569 22.3408 33.7934 22.3001C34.9454 21 36.9234 19.4419 39.7792 19.4419C43.7067 19.4419 46.9351 22.6147 46.976 26.516C47.011 29.9205 45.769 33.0638 43.1787 36.1248C41.9626 37.5611 39.7579 39.8585 34.6661 43.209C34.3224 43.4362 33.916 43.5578 33.4999 43.5582Z" fill={activeWishlist ? "red" : "#464646"} />
                                                            <defs>
                                                                <filter id="filter0_d_45_229" x="0" y="0" width="67" height="67" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                                    <feOffset dy="2" />
                                                                    <feGaussianBlur stdDeviation="1.5" />
                                                                    <feComposite in2="hardAlpha" operator="out" />
                                                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                                                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_45_229" />
                                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_45_229" result="shape" />
                                                                </filter>
                                                            </defs>
                                                        </svg>
                                                    </div>
                                                </div>

                                                <div className="productDetailsSmallImg_TrendingProduct">
                                                    {
                                                        quickView.productDetailsImgs?.map((images, index) => {
                                                            return (<>
                                                                <div style={{
                                                                    border: `${productZoomImg ? "1px solid red" : null}`
                                                                }} key={index} className="smallImgInnerDiv_TrendingProduct">
                                                                    <img key={index} onClick={() => setProductZoomImg(images.img)} src={images.img} />
                                                                </div>
                                                            </>)
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className="contentDetailsMainDiv_TrendingProduct">
                                                <div className="contentDetailsTitle_TrendingProduct">
                                                    <h1 style={contentDetailsTitle}>{quickView.title}</h1>
                                                </div>
                                                <div className="contentDetailsPriceDiv_TrendingProduct">
                                                    <h3 style={cardDetailsPricesStyle}>₹ {quickView.prize}&nbsp;&nbsp; <h4 style={{
                                                        color: "#5A5A5A",
                                                        fontWeight: "100"
                                                    }}>₹ {quickView.realPrize}</h4></h3>
                                                    <div style={{
                                                        display: "flex",
                                                        color: "black",
                                                        fontSize: `${cardPrice}px`,
                                                        alignItems: "center"
                                                    }}>
                                                        <ReactStars
                                                            count={5}
                                                            edit={false}
                                                            // onChange={ratingChanged}
                                                            value={quickView.rating}
                                                            size={16}
                                                            isHalf={true}
                                                            activeColor="#FFB100"
                                                            color1={'#dddddd'}
                                                            className="starComponent"
                                                        />
                                                        &nbsp; ({quickView.rating})
                                                    </div>
                                                </div>
                                                <div className="contentDetailsProductDetailsMainDIv_TrendingProduct">
                                                    <p
                                                    className="TP-product-info"
                                                    style={{
                                                        fontSize: `${cardPrice}px`
                                                    }}>Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag. Portfolio metoden poment mogesade, ossa. Revönde sygt yliga </p>
                                                </div>
                                                <div className="contentDetailsSizeDiv_TrendingProduct">
                                                    <div>
                                                        <h3 style={{
                                                            fontSize: `${cardPrice}px`,
                                                            color: "black",
                                                            padding: "1rem 0"
                                                        }} className="actualSizeViewContentDetails_TrendingProduct">Size: &nbsp;<span>{size}</span></h3>
                                                    </div>
                                                    <div className="sizeInnerMainDIv_TrendingProduct">
                                                        {
                                                            quickView?.sizes?.map((sizeValue, id) => {
                                                                return (<>
                                                                    <h2 onClick={() => setSize(sizeValue.size)} style={{
                                                                        fontSize: `${cardPrice * 0.9}px`,
                                                                        border: `${size ? "1px solid black" : "none"}`,
                                                                        cursor: "pointer"
                                                                    }}>
                                                                        {sizeValue.size}
                                                                    </h2>
                                                                </>)
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="quantityContentDetails_TrendingProduct">
                                                    <h2 style={{
                                                        fontSize: `${quantityFontSize}px`,
                                                        color: "#000"
                                                    }}>Quantity
                                                    </h2>
                                                    <div className="quantityMainDIv_TrendingProduct">
                                                        <button style={{
                                                            fontSize: `${quantityIncrDecrFontSize}px`
                                                        }} disabled={quantityIncre < 1} onClick={() => setQuantityIncre(quantityIncre - 1)}> - </button>
                                                        {quantityIncre}
                                                        <button style={{
                                                            fontSize: `${quantityIncrDecrFontSize}px`
                                                        }} onClick={() => setQuantityIncre(quantityIncre + 1)}> + </button>
                                                    </div>
                                                </div>
                                                <div className="cartAndBuyMainDiv_TrendingProduct">
                                                    <div className="addToCartMainDiv_TrendingProduct">
                                                        <button style={{
                                                            fontSize: `${btnFontSize}px`
                                                        }}>
                                                            Add to cart
                                                        </button>
                                                    </div>
                                                    <div className="BuyNowMainDiv_TrendingProduct">
                                                        <button style={{
                                                            fontSize: `${btnFontSize}px`
                                                        }}>
                                                            buy now
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="fullDetailsContentDetails_TrendingProduct">
                                                    <h2 style={{
                                                        fontSize: `${viewDetailsFontSize}px`
                                                    }}><a
                                                    style={{
                                                        textDecoration:"none",
                                                        color:"#545454"
                                                    }}
                                                    href={pagePaths.productDetails}>
                                                            view full details
                                                        </a> &nbsp;
                                                    </h2>
                                                    <svg width="9" height="9" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1 1L7 7.5L1 14" stroke="#545454" stroke-width="1.5" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>
        </div >
    </>)
}
