import React, { useRef, useState } from "react";
import "../styles/shopByCategeios.css";
import Carousel from 'react-elastic-carousel'
import CompanyLogo from "../svg/comapyLogo.svg";
import TrendingHeaderDash from "../svg/trendingProductDash.svg";
import { pagePaths } from "../utils/constant";
import { useContext } from "react";
import noteContext from "../context/noteContext";



const widthOutput = window.screen.width;
const trendingHeader = (1.8 / 100) * widthOutput;
const descriptionFontSize = (1.4 / 100) * widthOutput;

const trendingHeaderStyle = {
    fontSize: `${trendingHeader}px`,
    fontWeight: 800
}

const categoriesDummyArray = [
    {
        img: CompanyLogo,
        title: "Atta",
        id: 1
    },
    {
        img: CompanyLogo,
        title: "Atta2",
        id: 2
    },
    {
        img: CompanyLogo,
        title: "Atta3",
        id: 3
    },
    {
        img: CompanyLogo,
        title: "Atta4",
        id: 4
    },
    {
        img: CompanyLogo,
        title: "Atta5",
        id: 5
    },
    {
        img: CompanyLogo,
        title: "Atta6",
        id: 6
    },
]

const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 400, itemsToShow: 2 },
    { width: 600, itemsToShow: 3 },
    { width: 768, itemsToShow: 4 },
    { width: 992, itemsToShow: 5 },
    { width: 1200, itemsToShow: 6 },
    { width: 1400, itemsToShow: 7 },
  ];



export const ShopByCategories = () => {
    const { shopByCategoriesState } = useContext(noteContext)

    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === "PREV" ? [<svg width="36" height="36" viewBox="0 0 43 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="41" height="42" rx="2" stroke="#646464" stroke-width="1.5" />
            <path d="M25 15L18 22L25 29" stroke="#222222" stroke-width="1.5" />
        </svg>
        ] : [<svg width="36" height="36" viewBox="0 0 43 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="41" height="42" rx="2" transform="matrix(-1 0 0 1 42 1)" stroke="#646464" stroke-width="1.5" />
            <path d="M18 15L25 22L18 29" stroke="#222222" stroke-width="1.5" />
        </svg>
        ]
        // const pointer = "";
        console.log("checking myArrow", type, "==<<>>===", onClick, "==<<>>==", isEdge)
        return (
            <div style={{
                height: "100%",
                display: "flex",
                alignItems: "center"
            }}>
                <button style={{
                    background: "none",
                    border: "none",
                    height: "fit-content",
                    cursor: "pointer"
                }} onClick={onClick} disabled={isEdge}>
                    {pointer}
                </button>
            </div>
        )
    }

    return (<>
        <div ref={shopByCategoriesState} className="mainDIv_ShopByCategories">
            <div className="innerMainDIv_ShopByCategories">
                <div className="headerDiv_ShopByCategories">
                    <h1 style={trendingHeaderStyle}><img src={TrendingHeaderDash} />&nbsp; shop by category &nbsp; <img src={TrendingHeaderDash} /></h1>
                </div>
                <div className="innerInnerMainDIv_ShopByCategories">

                    <Carousel
                    breakPoints={breakPoints}
                        // itemsToShow={4}
                        renderArrow={myArrow}
                        pagination={false}
                    >
                        {
                            categoriesDummyArray.map((image) => (
                                <div className="mainDiv_OwlCarouselShopByCategories">
                                    <div className="innerMainDiv_OwlCarouselShopByCategories">
                                        <a
                                            href={pagePaths.productLandingPage}
                                        >
                                            <div className="owlCarosoulCard_ShopByCategories">
                                                <img src={image.img} />
                                            </div>
                                        </a>
                                        <div className="owlCarosoulCardTitle_ShopByCategories">
                                            <h2 style={{
                                                fontSize: `${descriptionFontSize}px`,
                                                fontWeight: 500
                                            }}>
                                                {image.title}
                                            </h2>
                                        </div>
                                    </div>

                                </div>
                            ))
                        }
                    </Carousel>
                </div>
            </div>
        </div >
    </>)
}