import { Routes, Route } from "react-router-dom"
import { pagePaths } from "./utils/constant"
import Dashboard from "./component/Dasboard";
import ProductdetailsPage from "./container/ProductDetails/ProductdetailsPage";
import { ContactUs } from "./container/contactUs";
import { WriteReview } from "./container/reviewPage";
import { AccountSection } from "./container/accountSection";
import { ShippingDetails } from "./container/shippingDetailsPage";
import { OfferPage } from "./container/offerPages";
import { PrivacyPolicy } from "./container/privacyPolicy";
import { FAQ } from "./container/faq";
import { TermsAndConditions } from "./container/termsAndConditions";
import { CancelliationReturnAndExchange } from "./container/cancellationReturnAndExchange";
import { TremsANdServices } from "./container/termsAnsServices";
import { RefundPolicy } from "./container/refundPolicy";
import Aboutus from "./container/Aboutus/Aboutus";
import ProductLanding from "./container/ProductLanding.js/ProductLanding";
import Wishlist from "./container/ProductDetails/Wishlist";
import Cart from "./container/Cart/Cart";
import { ShopByCategories } from "./container/shopByCategories";

const AppRouter = () => {

    return (
        <Routes>
            <Route path={pagePaths.root} element={<Dashboard />} />
            <Route path={pagePaths.productDetails} element={<ProductdetailsPage />} />
            <Route path={pagePaths.contactUs} element={<ContactUs />} />
            <Route path={pagePaths.writeReview} element={<WriteReview />} />
            <Route path={pagePaths.accountSection} element={<AccountSection />} />
            <Route path={pagePaths.shippingDetailsPage} element={<ShippingDetails />} />
            <Route path={pagePaths.offerPage} element={<OfferPage />} />
            <Route path={pagePaths.privacyPolicy} element={<PrivacyPolicy />} />
            <Route path={pagePaths.faq} element={<FAQ />} />
            <Route path={pagePaths.termsAndConditons} element={<TermsAndConditions />} />
            <Route path={pagePaths.cancellationReturnAndExchange} element={<CancelliationReturnAndExchange />} />
            <Route path={pagePaths.tremsAndServices} element={<TremsANdServices />} />
            <Route path={pagePaths.refundPolicy} element={<RefundPolicy />} />
            <Route path={pagePaths.aboutUsPage} element={<Aboutus />} />
            <Route path={pagePaths.productLandingPage} element={<ProductLanding />} />
            <Route path={pagePaths.wishlist} element={<Wishlist />} />
            <Route path={pagePaths.cart} element={<Cart />} />
            <Route path={pagePaths.shopByCategories} element={<ShopByCategories />} />
        </Routes>
    )
}

export default AppRouter;