import React, { useState } from "react";
import "../styles/reviewPage.css";
import Layout from "../component/layout";
import { HeadingHeader } from "./headingHeader";
import { TextField } from "@material-ui/core";
import DummyImg from "../images/dummyImg.png";
import ReactStars from "react-stars";

const widthoutput = window.screen.width;
const reviewTitleFontSize = (1.2 / 100) * widthoutput;
const submitBtnFIntSize = (1 / 100) * widthoutput;
const productContentDetailsFontSize = (1 / 100) * widthoutput;

const giveReviewStyle = {
    fontSize: `${reviewTitleFontSize}px`
}


export const WriteReview = () => {
    const [totalRaitingNumber, setTotalRaitingNumber] = useState(3.2)
    const [addRaitingNumber, setaddRaitingNumber] = useState(0)

    const ratingTotal = (newRating) => {
        console.log(newRating);
        setTotalRaitingNumber(newRating)
    };
    const Addrating = (newRating) => {
        console.log(newRating);
        setaddRaitingNumber(newRating)
    };
    const raitingPoint = () => {
        if (Number.isInteger(totalRaitingNumber)) {
            return totalRaitingNumber + ".0"
        } else {
            return totalRaitingNumber
        }
    }

    console.log("checking Raiting", raitingPoint())


    return (
        <Layout>
            <div className="mainDiv_WriteReview">
                <div className="header_WriteReview">
                    <HeadingHeader title="Write review" />
                </div>
                <div className="lowerSectionMainDIv_WriteReview">
                    <div className="lowerSection_WriteReview">
                        <div className="lowerLeftMainDIv_WriteReview">
                            <div className="lowerInnerLeftMainDIv_WriteReview">
                                <div className="lowerLeftHeader_WriteReview">
                                    <h2 style={giveReviewStyle}>Give us your review</h2>
                                </div>
                                <div className="NameTextField_WriteReview">
                                    <TextField
                                        id="standard-helperText"
                                        label="Name"
                                        defaultValue="Mohit Chauhan"
                                        // helperText="Some important text"
                                        variant="outlined"
                                        fullWidth={true}
                                    />
                                </div>
                                <div className="raitingMainDIv_WriteReview">
                                    <h1 style={giveReviewStyle}>Adding Ratings</h1>
                                    <ReactStars
                                        count={5}
                                        edit={true}
                                        onChange={Addrating}
                                        value={addRaitingNumber}
                                        size={40}
                                        // isHalf={false}
                                        activeColor="#FFB100"
                                        color1={'#dddddd'}
                                        className="starComponent"
                                    />
                                    <h1 style={{
                                        fontSize: `${reviewTitleFontSize * 0.7}px`
                                    }}>{addRaitingNumber}/5 Selected</h1>
                                </div>
                                <div className="NameTextField_WriteReview">
                                    <TextField
                                        id="standard-helperText"
                                        label="Title (Optional)"
                                        defaultValue="Write Here"
                                        // helperText="Some important text"
                                        variant="outlined"
                                        fullWidth={true}
                                    />
                                </div>
                                <div className="NameTextField_WriteReview">
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Description"
                                        multiline
                                        rows={6}
                                        defaultValue="Write Here"
                                        variant="outlined"
                                        fullWidth={true}
                                    />
                                </div>
                                <div className="submitBtn_WriteReview">
                                    <button style={{
                                        fontSize: `${submitBtnFIntSize}px`
                                    }}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="lowerRightMainDIv_WriteReview">
                            <div className="lowerLeftHeader_WriteReview">
                                <h2 style={giveReviewStyle}>Product:</h2>
                            </div>
                            <div className="ProductDetailsImg_WriteReview">
                                <img src={DummyImg} />
                            </div>
                            <div className="productContentDetails_WriteReview">
                                <h1 style={{
                                    fontSize: `${productContentDetailsFontSize}px`
                                }}>fresh sunflower oil 990ml </h1>
                                <div className="raitingMainDIvContent_WriteReview">
                                    <h1 style={{
                                        fontSize: `${reviewTitleFontSize * 0.9}px`
                                    }}>{raitingPoint()}</h1>
                                    <ReactStars
                                        count={5}
                                        edit={true}
                                        // onChange={ratingTotal}
                                        value={totalRaitingNumber}
                                        size={20}
                                        isHalf={false}
                                        activeColor="#FFB100"
                                        color1={'#dddddd'}
                                        className="starComponent"
                                    />

                                </div>
                                <h2 style={{
                                    fontSize: `${productContentDetailsFontSize * 0.9}px`
                                }}>based on 23 raitings</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    )
}