


import '../../styles/ProductDetails/Wishlist.css';
import React, { useState } from 'react'
import Layout from '../../component/layout';
import filledstar from '../../svg/Wishlist/filteredstar.svg';
import lockicon from '../../svg/Wishlist/lockicon.svg';
import cancelicon from '../../svg/Wishlist/cancelicon.svg';
import left_arrow from '../../svg/Wishlist/left_arrow.svg';


export default function Wishlist() {
    const [wishlistItems, setWishlistItems] = 
useState([
    {
        name: 'Fresh Sunflower Oil 990ml',
        imageSrc: 'path-to-image', 
        alt: 'alt',
        currentPrice: 210,
        originalPrice: 630,
    }, {
        name: 'Fresh Sunflower Oil 990ml',
        imageSrc: 'path-to-image', 
        alt: 'alt',
        currentPrice: 210,
        originalPrice: 350,
    }, {
        name: 'Fresh Sunflower Oil 990ml',
        imageSrc: 'path-to-image', 
        alt: 'alt',
        currentPrice: 210,
        originalPrice: 350,
    },
    {
        name: 'Fresh Sunflower Oil 990ml',
        imageSrc: 'path-to-image', 
        alt: 'alt',
        currentPrice: 210,
        originalPrice: 130,
    },
    {
        name: 'Fresh Sunflower Oil 990ml',
        imageSrc: 'path-to-image', 
        alt: 'alt',
        currentPrice: 210,
        originalPrice: 320,
    },
    {
        name: 'Fresh Sunflower Oil 990ml',
        imageSrc: 'path-to-image', 
        alt: 'alt',
        currentPrice: 210,
        originalPrice: 350,
    },
    {
        name: 'Fresh Sunflower Oil 990ml',
        imageSrc: 'path-to-image', 
        alt: 'alt',
        currentPrice: 220,
        originalPrice: 310,
    },
    {
        name: 'Fresh Sunflower Oil 990ml',
        imageSrc: 'path-to-image', 
        alt: 'alt',
        currentPrice: 210,
        originalPrice: 330,
    },
])

    const handleRemoveItem = (indexToRemove) => {
        const updatedWishlist = wishlistItems.filter((_, index) => index !== indexToRemove);
        setWishlistItems(updatedWishlist);
    };

    const handleClearWishlist = () => {
        setWishlistItems([]);
    }

    return (
        <div>
            <Layout>
                <div>
                    <div className='wishlist-header-container'>
                        <span className='home-txt'>Home /</span>
                        <span className='sunflower-oil'> Wishlist</span>
                    </div>

                    <div className='wish-main-container'>
                        <div className='wish-sub-main-container'>
                            <div className='mywishlist-container'>
                                <p className='wishlist-txt'>my wishlist: <span className='wishlist-items'> ({wishlistItems.length} items)</span></p>
                                <p className='clear-all' onClick={handleClearWishlist}>Clear Wishlist</p>
                            </div>

                            <div>
                                {wishlistItems.map((item, index) => (
                                    <div key={index} className='wishlist-container-contents'>
                                        <div className='wishlist-container2'>
                                            <div className='wishlist-img-container'>
                                                <img src={item.imageSrc} alt={item.alt} />
                                            </div>
                                            <div className='wishlist-content'>
                                                <p className='selected-items'>{item.name}</p>
                                                <div className='wish-multi-images'>
                                                    <span>
                                                        {Array.from({ length: 5 }).map((_, starIndex) => (
                                                            <img key={starIndex} src={filledstar} alt='alt' className="wish-filled-images1" />
                                                        ))}
                                                    </span>
                                                </div>
                                                <div className='wish-rates'>
                                                    <span className='wish-rates-txt'><span className='wish-rupee'>₹</span>{item.currentPrice}</span>
                                                    <span className='wish-rates-txt-cancel'>₹ {item.originalPrice}</span>
                                                </div>

                                                <div className='addtocart-btn'>
                                                    <button className='wish-add-to-cart-btn'>
                                                        <span className='wish-lockicon'><img className='wish-lockicon-img' src={lockicon} alt='alt' /></span>ADD TO CART
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='cancellation-cross-icon' onClick={() => handleRemoveItem(index)}>
                                            <img src={cancelicon} alt='alt' className='cancellation-img'/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        
                        <div className='continue-shopping'>
                            <span>Continue Shopping</span><img src={left_arrow}/>
                        </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}
