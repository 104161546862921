import React from "react";
import "../styles/offerPages.css";
import Layout from "../component/layout";
import { HeadingHeader } from "./headingHeader";
import Img1 from "../images/img1.png";
import Img2 from "../images/img2.png";
import BreadCrum from "../component/BreadCrum";

const widthOutput = window.screen.width;
const shopNowFontSize = (1.5 / 100) * widthOutput;

const ShopNowStyles = {
    fontSize: `${shopNowFontSize}px`
}


const offerCardDetailsList = [
    {
        img: Img1,
    },
    {
        img: Img2,
    },
    {
        img: Img1,
    },
    {
        img: Img2,
    },
    {
        img: Img1,
    },

]


export const OfferPage = () => {
    return (
        <Layout>
            <BreadCrum currentPageName="Offers" />
            <div className="mainDIv_OfferPage">
                <div className="innerMainDiv_OfferPage">
                    <div className="headerMainDiv_OfferPage">
                        <HeadingHeader title="BEST DEALS PRODUCTS" />
                    </div>
                    <div className="offerProductListMainDiv_OfferPage">
                        {
                            offerCardDetailsList?.map((offerList, id) => {
                                return (<>
                                    <div className="offerProductListInnerMainDiv_OfferPage">
                                        <div className="offerProductListCardMainDiv_OfferPage">
                                            <div className="productImgMainDiv_OfferPage">
                                                <img src={offerList.img} />
                                            </div>
                                            <div className="shopNowMainDIv_OfferPAge">
                                                <button>Shop now</button>
                                            </div>
                                        </div>
                                    </div>
                                </>)
                            })
                        }
                    </div>
                </div>
            </div>
        </Layout>
    )
}