import React, { useContext, useEffect, useState } from "react";
import "../styles/toolbar.css";
import CompanyLogo from "../svg/comapyLogo.svg";
import DropDownIcon from "../svg/dropdown.svg";
import SearchIcon from "../svg/searchIcon.svg";
import AccountIcon from "../svg/accountIcon.svg";
import WishListIcon from "../svg/wishList.svg";
import CartIcon from "../svg/cartIcon.svg";
import { pagePaths } from "../utils/constant";
import noteContext from "../context/noteContext";
import CrossIcon from "../svg/cross.svg";
import img1 from "../images/img1.png";
import DeleteIcon from "../svg/deleteIcon.svg";
import { Badge } from "@material-ui/core";
import crossMarkIcon from "../svg/cross.svg";
import MenuIcon from "../svg/toolbar/menuicon.svg";

const widthOutput = window.screen.width;
const categoriesFontSize = (1.1 / 100) * widthOutput;
const accountSectionFontSize = (0.7 / 100) * widthOutput;
const cartHeaderTitleFontSize = (1.5 / 100) * widthOutput;
const sideNavProductTitleFOntSize = (0.8 / 100) * widthOutput;
const quantityIncrDecrFontSize = (1 / 100) * widthOutput;
const subTotalFOntSize = (1.2 / 100) * widthOutput;
const viewCartBtnFontSize = (1.1 / 100) * widthOutput;

const categorie_style = {
    fontSize: `${categoriesFontSize}px`,
    fontWeight: 500,
    textTransform: "uppercase",
    color: "#474747"
}
const accountSectionStyle = {
    fontSize: `${accountSectionFontSize}px`,
    textTransform: "uppercase",
    // color: "#999",
    fontWeight: 600
}
const headertitleNavSIdeStyle = {
    fontSize: `${cartHeaderTitleFontSize}px`,
}
const sideNaveProductTitleSTyle = {
    fontSize: `${sideNavProductTitleFOntSize}px`,
    fontWeight: 100
}
const sideNavProductPriceStyle = {
    fontSize: `${sideNavProductTitleFOntSize}px`,
}
const sideNavProductSizeStyle = {
    fontSize: `${sideNavProductTitleFOntSize * 0.9}px`,
}

const sideNavCartDummyArray = [
    {
        img: img1,
        title: "fresh sunflower oil ny sunpure fresh sunflower oil ny sunpure",
        price: 210,
        actualPrice: 350,
        size: "900ml",
        sizes: [
            {
                size: "900ml"
            },
            {
                size: "1900ml"
            },
            {
                size: "400ml"
            },
        ],
    },
    {
        img: img1,
        title: "fresh sunflower oil ny sunpure",
        price: 210,
        actualPrice: 350,
        size: "900ml",
        sizes: [
            {
                size: "900ml"
            },
            {
                size: "1900ml"
            },
            {
                size: "400ml"
            },
        ],
    },
    {
        img: img1,
        title: "fresh sunflower oil ny sunpure",
        price: 210,
        actualPrice: 350,
        size: "900ml",
        sizes: [
            {
                size: "900ml"
            },
            {
                size: "1900ml"
            },
            {
                size: "400ml"
            },
        ],
    },
    {
        img: img1,
        title: "fresh sunflower oil ny sunpure",
        price: 210,
        actualPrice: 350,
        size: "900ml",
        sizes: [
            {
                size: "900ml"
            },
            {
                size: "1900ml"
            },
            {
                size: "400ml"
            },
        ],
    },
    {
        img: img1,
        title: "fresh sunflower oil ny sunpure",
        price: 210,
        actualPrice: 350,
        size: "900ml",
        sizes: [
            {
                size: "900ml"
            },
            {
                size: "1900ml"
            },
            {
                size: "400ml"
            },
        ],
    },
]



export const Toolbar = () => {
    const [openSideNavCart, setOpenSideNavCart] = useState(false);
    const [quantityIncre, setQuantityIncre] = useState(0);
    const [sideNavSizeDropDown, setSideNavDropDown] = useState()
    const [sideNavSizeActiveDropDown, SetsideNavSizeActiveDropDown] = useState()
    const [scrollTop, setScrollY] = useState();
    const [openSearchBar, setOpenSearchBar] = useState(false)
    const { isHome, activeAccount, activeWishList, activeCart, totalItemWishlist } = useContext(noteContext);
    const [showMenuData, setShowMenuData] = useState(false)
    useEffect(() => {
        const handleScroll = () => {
            // Update the state with the current scroll position
            setScrollY(window.scrollY);
        };

        // Attach the event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Detach the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleSizeDropDown = (sizeLists) => {
        setSideNavDropDown(sizeLists)
        SetsideNavSizeActiveDropDown(!sideNavSizeActiveDropDown)
    }

const handleMenu = () => {
    setShowMenuData(!showMenuData)
    console.log("checking menu" , showMenuData)
}

    console.log("checking true==>>>", activeAccount)

    return (<>
        <div
            style={{
                boxShadow: scrollTop > 0 ? "0px 0px 16px rgba(0, 0, 0, 0.06)" : "none",
            }} className="mainDiv_Toolbar">
            <div className="innerMainDiv_Toolbar">
                <div className="logoMainDiv_ToolBar">
                    <img src={CompanyLogo} />
                </div>
                {
                    openSearchBar ?
                        <div className="searchBarMainDiv_ToolBar">
                            <div className="searchBarInnerMainDiv_Tool">
                                <input placeholder="Search Products" />
                                <img onClick={() => setOpenSearchBar(false)} src={crossMarkIcon} />
                            </div>
                        </div>
                        :
                        <>
                            <div className="menu-container-toolbar">
                                <img src={MenuIcon} alt="alt" className="menuicon-toolbar" onClick={()=>handleMenu()} />
                            </div>
                           
                           <div className={`${!showMenuData ? "toolbarCategoriesMainDiv_Toolbar-navbar mobile-toolbarCategoriesMainDiv_Toolbar-navbar": " mobile-toolbarCategoriesMainDiv_Toolbar-navbar"}`}>
                                <div className="categoriesMainDIv_Toolbar">
    
                                    <h2 style={categorie_style}>
                                        <a
                                            href={pagePaths.root}
                                            style={{
                                                textDecoration: "none",
                                                color: "#474747",
                                                fontFamily: "Poppins-Regular",
    
                                            }}
                                        >
                                            HOME
                                        </a>
                                    </h2>
    
                                    <h2 style={categorie_style}>Riso store</h2>
                                    <h2 style={categorie_style}>
                                        <a
                                            href={pagePaths.offerPage}
                                            style={{
                                                textDecoration: "none",
                                                color: "#474747",
                                                fontFamily: "Poppins-Regular",
                                            }}
                                        >
                                            offers
                                        </a>
                                    </h2>
                                    <h2 className="categoriesTitle_Toolbar" style={categorie_style}>categories &nbsp;
                                        <img className="dropdownCategories_Toolbar" src={DropDownIcon} />
                                        <div className="categoriesDropDown_Toolbar">
                                            <h1 style={categorie_style}>
                                                <a
                                                    href={pagePaths.productLandingPage}
                                                    style={{
                                                        textDecoration: "none",
                                                        color: "#474747"
                                                    }}
                                                >
                                                    Atta
                                                </a>
                                            </h1>
                                            <h1 style={categorie_style}>
                                                <a
                                                    href={pagePaths.productLandingPage}
                                                    style={{
                                                        textDecoration: "none",
                                                        color: "#474747"
                                                    }}
                                                >
                                                    Atta
                                                </a>
                                            </h1>
                                            <h1 style={categorie_style}>
                                                <a
                                                    href={pagePaths.productLandingPage}
                                                    style={{
                                                        textDecoration: "none",
                                                        color: "#474747"
                                                    }}
                                                >
                                                    Atta
                                                </a>
                                            </h1>
                                            <h1 style={categorie_style}>
                                                <a
                                                    href={pagePaths.productLandingPage}
                                                    style={{
                                                        textDecoration: "none",
                                                        color: "#474747"
                                                    }}
                                                >
                                                    Atta
                                                </a>
                                            </h1>
                                            <h1 style={categorie_style}>
                                                <a
                                                    href={pagePaths.productLandingPage}
                                                    style={{
                                                        textDecoration: "none",
                                                        color: "#474747"
                                                    }}
                                                >
                                                    Atta
                                                </a>
                                            </h1>
                                        </div>
                                    </h2>
                                    <h2 style={categorie_style}>
                                        <a
                                            href={pagePaths.contactUs}
                                            style={{
                                                textDecoration: "none",
                                                color: "#474747",
                                                fontFamily: "Poppins-Regular",
                                            }}
                                        >
                                            contact
                                        </a>
                                    </h2>
                                </div>
                                <div className="accountSection_Toolbar">
                                    <div className="accountSectionInner_Toolbar">
                                        <div onClick={() => setOpenSearchBar(true)} style={{
                                            color: "#999"
                                        }} className="accountSectionContent_Toolbar">
                                            <img src={SearchIcon} />
                                            <h2 style={accountSectionStyle}>search</h2>
                                        </div>
    
                                        <a
                                            href={pagePaths.accountSection}
                                            style={{
                                                textDecoration: "none",
                                                color: `${activeAccount ? "black" : "#999"}`,
                                                borderBottom: `${activeAccount ? "1px solid black " : "1px solid transparent"}`
                                            }}
                                        >
                                            <div
                                                style={{
                                                }}
                                                className="accountSectionContent_Toolbar">
                                                <img src={AccountIcon} />
                                                <h2 style={accountSectionStyle}>account
                                                </h2>
                                            </div>
                                        </a>
    
                                        <a
                                            href={pagePaths.wishlist}
                                            style={{
                                                textDecoration: "none",
                                                color: `${activeWishList ? "black" : "#999"}`,
                                                borderBottom: `${activeWishList ? "1px solid black " : "1px solid transparent"}`
                                            }}>
                                            <div className="accountSectionContent_Toolbar">
                                                <Badge color="error" badgeContent={8}>
                                                    <img style={{
                                                        position: "relative",
                                                        top: "18%",
                                                        right: "18%",
                                                    }} src={WishListIcon} />
                                                </Badge>
                                                <h2 style={accountSectionStyle}>wishlist</h2>
                                            </div>
                                        </a>
                                        <div style={{
                                            cursor: "pointer",
                                            color: `${activeCart ? "black" : "#999"}`,
                                            borderBottom: `${activeCart ? "1px solid black " : "1px solid transparent"}`
                                        }} onClick={() => setOpenSideNavCart(true)} className="accountSectionContent_Toolbar">
                                            <img src={CartIcon} />
                                            <h2 style={accountSectionStyle}>cart</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div >
        </div >
        <div className="sideCartNavMAinDiv_ToolBar">
            {
                openSideNavCart ?
                    <div className="sideCartNavInnerMainDiv_ToolBar">
                        <div className="sideInnerInnerNavMainDiv_ToolBar">
                            <div className="HeaderTitleSIdeNavMainDiv_ToolBar">
                                <div className="titleHeaderInnerDiv_ToolBar">
                                    <h1 style={headertitleNavSIdeStyle}>my cart <span>(3 items)</span></h1>
                                </div>
                                <div onClick={() => setOpenSideNavCart(false)} className="crossIconHeaderTitle_ToolBar">
                                    <img src={CrossIcon} />
                                </div>
                            </div>
                            <div className="cartProductDetailsSideNavMainDIv_ToolBar">
                                {
                                    sideNavCartDummyArray?.map((productDetails, id) => {
                                        return (<>
                                            <div className="cartProductDetailsSideNav_ToolBar">
                                                <div className="productImgMainDivSideNav_ToolBAr">
                                                    <img src={img1} />
                                                </div>
                                                <div className="productDetailsCOntentSideNav_Toolbar">
                                                    <h2 className="sideNaveProductTitle_Toolbar" style={sideNaveProductTitleSTyle}>{productDetails.title}</h2>
                                                    <h3 style={sideNavProductPriceStyle}>₹ {productDetails.price}&nbsp; <span className="actualPriceTagSideNav_Toolbar">₹ {productDetails.actualPrice}</span></h3>
                                                    <div className="productSizeContentSideNav_Toolbar">
                                                        <h4 style={sideNavProductSizeStyle}>{productDetails.size} &nbsp; <img onClick={() => handleSizeDropDown(productDetails.sizes)} src={DropDownIcon} />
                                                        </h4>
                                                        <div className="sideNavSizeDropDown_Toolbar">
                                                            {
                                                                sideNavSizeActiveDropDown ? <div>
                                                                    {
                                                                        sideNavSizeDropDown?.map((sizelist, id) => {
                                                                            return (<>
                                                                                <h4 style={sideNavProductSizeStyle}>{sizelist.size}</h4>
                                                                            </>)
                                                                        })
                                                                    }
                                                                </div> : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="quantityMainDIvSideNav_Toolbar">
                                                        <button style={{
                                                            fontSize: `${quantityIncrDecrFontSize}px`
                                                        }} disabled={quantityIncre < 1} onClick={() => setQuantityIncre(quantityIncre - 1)}> - </button>
                                                        <h2 style={{
                                                            fontSize: `${quantityIncrDecrFontSize}px`,
                                                            fontWeight: 100
                                                        }}>{quantityIncre}</h2>
                                                        <button style={{
                                                            fontSize: `${quantityIncrDecrFontSize}px`
                                                        }} onClick={() => setQuantityIncre(quantityIncre + 1)}> + </button>
                                                    </div>
                                                </div>
                                                <div className="deleteBtnSideNav_Toolbar">
                                                    <img src={DeleteIcon} />
                                                </div>
                                            </div>

                                        </>)
                                    })
                                }
                            </div>
                            <div className="subTotalAndBtnMainDivSideNav_Toolbar">
                                <div className="subTotalMainDivSudeNav_Toolbar">
                                    <h1 style={{
                                        fontSize: `${subTotalFOntSize}px`
                                    }}>Subtotal</h1>
                                    <h2 style={{
                                        fontSize: `${subTotalFOntSize}px`
                                    }}>₹ 210</h2>
                                </div>
                                <div className="cartANdBuyBtnSideNav_Toolbar">
                                    <a
                                        href={pagePaths.cart}
                                        style={{
                                            textDecoration: "none",
                                            color: "#999",
                                            width: "47%"
                                        }}>
                                        <button style={{
                                            fontSize: `${viewCartBtnFontSize}px`,
                                            background: "#292929",
                                            color: "white"
                                        }}>
                                            View cart
                                        </button>
                                    </a>
                                    <a
                                        href="#"
                                        style={{
                                            textDecoration: "none",
                                            color: "#999",
                                            width: "47%"
                                        }}>
                                        <button style={{
                                            fontSize: `${viewCartBtnFontSize}px`
                                        }}>
                                            buy now
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </div>
    </>)
}