import React, { useContext, useEffect } from "react";
import "../styles/homePage.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import HomePageBackground from "../images/homePageImg1.png";
import FastShippingIcon from "../svg/fastShippingIcon.svg";
import QualitySupportIcon from "../svg/qualitySupportIcon.svg";
import SecurityIcon from "../svg/secutityIcon.svg";
import NewLetterIcon from "../svg/newLatterIcon.svg";
import noteContext from "../context/noteContext";

const widthOutput = window.screen.width;
const heightOutput = window.screen.height;

const backGroundImgHeight = (80 / 100) * heightOutput;

const headingFontSize = (1.35 / 100) * widthOutput;
const headingMeddleFontSize = (3.5 / 100) * widthOutput;
const descriptionFontSize = (1 / 100) * widthOutput;


const showNowBtn = (10 / 100) * widthOutput;
const showNowBtnFontSize = (1 / 100) * widthOutput;

const overlayHeaderFontSize = (1.2 / 100) * widthOutput;
const overlayContentFOntSize = (0.9 / 100) * widthOutput;



console.log("checking width of show btn", showNowBtn)

const mainDivStyle = {
    backgroundImage: `url(${HomePageBackground})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    // backgroundAttachment: "fixed",
    width: "100%",
    height: `${backGroundImgHeight}px`,
    display: "flex",
    zIndex: 1,
    height: "100vh"
}


const HomePageArray = [
    {
        BgImg: HomePageBackground,
        upperText: "High quality",
        middleText: "make your food",
        lowerText1: "healthier &",
        lowerText2: "Tastier",
        description: "Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag."
    },
    {
        BgImg: HomePageBackground,
        upperText: "Live your dance",
        middleText: "Create",
        lowerText: "Memories",
        description: "Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag."
    },
    {
        BgImg: HomePageBackground,
        upperText: "Live your dance",
        middleText: "Create",
        lowerText: "Memories",
        description: "Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag."
    },
    {
        BgImg: HomePageBackground,
        upperText: "Live your dance",
        middleText: "Create",
        lowerText: "Memories",
        description: "Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag."
    },
]

const overlayHeaderStyle = {
    fontSize: `${overlayHeaderFontSize}px`,
    textTransform: "capitalize",
    paddingTop: "1rem",
    color: "#222"
}
const overlayContentStyle = {
    fontSize: `${overlayContentFOntSize}px`,
    textTransform: "capitalize",
    paddingTop: "0.3rem",
    color: "#888",
    fontWeight: 100
}


export const HomePage = () => {
    const { setIsHome, toHome } = useContext(noteContext);
    useEffect(() => {
        setIsHome(true)
    }, [])
    return (<>
        <OwlCarousel
            className="owl-theme"
            ref={toHome}
            dots={true}
            nav={true}
            autoplay={false}
            autoplayTimeout={2000}
            autoplayHoverPause={true}
            loop={true}
            navText={[
                `<svg width="36" height="36" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22 15L15 22L22 29" stroke="#E22518" stroke-width="1.5" />
                    <path d="M29 15L22 22L29 29" stroke="#E22518" stroke-width="1.5" />
                    <rect x="1" y="1" width="42" height="42" rx="2" stroke="#E22518" stroke-width="1.5" />
                </svg>
                `
                , `<svg width="36" height="36" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22 15L29 22L22 29" stroke="#E22518" stroke-width="1.5"/>
                <path d="M15 15L22 22L15 29" stroke="#E22518" stroke-width="1.5"/>
                <rect width="42" height="42" rx="2" transform="matrix(-1 0 0 1 43 1)" stroke="#E22518" stroke-width="1.5"/>
                </svg>
                `]}
            items={1}
        >
            {
                HomePageArray?.map((item, id) => {
                    return (<>
                        <div style={{
                            backgroundImage: `url(${item.BgImg})`,
                        }} className="mainDiv_HomePage">

                            <div className="innerMainDiv_HomePage">
                                <div className="bannerHeadding_HomePage">
                                    <div className="bannerInnerMainDiv_HomePage">
                                        <div className="bannerUpperHeading_Homepage">
                                            <h1 className="UpperHeading_HomePage" style={{
                                                fontSize: `${headingFontSize}px`,
                                            }}>{item.upperText}</h1>
                                        </div>
                                        <div className="bannerMiddleHeading_HomePage">
                                            <div> <h1 className="MiddleHeading_HomePage" style={{
                                                fontSize: `${headingMeddleFontSize}px`,
                                            }}>{item.middleText}</h1></div>
                                        </div>
                                        <div className="bannerLowerHeading_HomePage">
                                            <h1 className="LowerHeading_HomePage" style={{
                                                fontSize: `${headingMeddleFontSize}px`,
                                            }}>{item.lowerText1} <span>{item.lowerText2}</span></h1>
                                        </div>
                                        <div className="bannerLowerPara_HomePage">
                                            <h1 className="LowerPara_HomePage" style={{
                                                fontSize: `${descriptionFontSize}px`
                                            }}>{item.description}</h1>
                                        </div>
                                    </div>
                                    <div className="showNowBtn_HoemPage">
                                        <button style={{
                                            width: `${showNowBtn * 1.2}px`,
                                            fontSize: `${showNowBtnFontSize * 1.3}px`
                                        }}>
                                            Shop Now
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>)
                })
            }
        </OwlCarousel >
        <div className="overlapMenuMainDiv_HomePage">
            <div className="overlapMenuInnerDiv_HomePage">
                <div className="overlapContentDiv_HomePage">
                    <img src={FastShippingIcon} />
                    <h1 style={overlayHeaderStyle}>fast shipping</h1>
                    <h2 style={overlayContentStyle}>get timely deliveries</h2>
                </div>
                <div className="overlapContentDiv_HomePage right">
                    <img src={QualitySupportIcon} />
                    <h1 style={overlayHeaderStyle}>quality support</h1>
                    <h2 style={overlayContentStyle}>get 24/7 support</h2>
                </div>
                <div className="overlapContentDiv_HomePage">
                    <img src={SecurityIcon} />
                    <h1 style={overlayHeaderStyle}>secure checkout</h1>
                    <h2 style={overlayContentStyle}>your checkouts are secured</h2>
                </div>
                <div style={{
                    border: "none"
                }} className="overlapContentDiv_HomePage">
                    <img src={NewLetterIcon} />
                    <h1 style={overlayHeaderStyle}>Join Newsletter</h1>
                    <h2 style={overlayContentStyle}>get extra offers by joinning</h2>
                </div>
            </div>
        </div>
    </>)
}