import React from "react";
import "../styles/cancellationReturnAndExchange.css";
import Layout from "../component/layout";
import BreadCrum from "../component/BreadCrum";


const widthOutput = window.screen.width;
const headerJHeadingFontSize = (2.2 / 100) * widthOutput;
const titleHeaderFontSize = (1.6 / 100) * widthOutput;

const headerHeadingStyles = {
    fontSize: `${headerJHeadingFontSize}px`
}

export const CancelliationReturnAndExchange = () => {
    return (
        <Layout>
            <BreadCrum currentPageName="cancellation, return and exchange" />
            <div className="mainDiv_CancellationReturnAndExchange">
                <div className="innerMainDiv_CancellationReturnAndExchange">
                    <div className="headerWithCOntentMainDiv_CancellationReturnAndExchange">
                        <div className="headerHeading_CancellationReturnAndExchange">
                            <h1 style={headerHeadingStyles}>cancellation, return and exchange</h1>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_CancellationReturnAndExchange">
                        <div className="termsOfUseTitleMainDiv_CancellationReturnAndExchange">
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`,
                            }}>1. An order placed and confirmed cannot be cancelled.
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>2. You are entitled to a 100% replacement of order in case you received something else than what you ordered or your order reached you in a distorted manner.
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}> 3. Replacement process must be initiated within 24 hours from the time of receipt of order by the User. The Product must be in an unused state before seeking for a replacement and should be accompanied by a copy of the original receipt or invoice issued at the time of sale of the Product.
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>4. Any request for replacement will be accepted only after inspection of the Product. Once the replacement request is placed with the customer service, the logistics partner will arrange for a pickup of the Product from the address provided at the time of placing the order. Once the Product is received, it takes about 1-2 working day for inspection team to confirm on the replacement. On confirmation of the genuine need for replacement of the Product, we shall replace the desired Product with the User in 3 working days.
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}