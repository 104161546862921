

// import '../../styles/ProductDetails/ProductDetailsPage.css'
import '../../styles/ProductDetails/ProductdetailsPage.css';
import React, { useState } from 'react'
import { Toolbar } from '../toolbar'
import heart from '../../svg/Productdetails/Heart.svg'
import filledstar from '../../svg/Productdetails/filledstar.svg'
import Layout from '../../component/layout'
import icon1 from '../../svg/Productdetails/icon1.svg';
import icon2 from '../../svg/Productdetails/icon2.svg';
import icon3 from '../../svg/Productdetails/icon3.svg';
import icon4 from '../../svg/Productdetails/icon4.svg';
import hdfcbank from '../../images/Productdetails/hdfcbank.png';
import redyellow from '../../images/Productdetails/redyellow.png';
import redblue from '../../images/Productdetails/redblue.png';
import googlepay from '../../images/Productdetails/googlepay.png';
import visa from '../../images/Productdetails/visa.png';
import like from '../../svg/Productdetails/like.svg';
import dislike from '../../svg/Productdetails/dislike.svg';
// import filledstar from '../../svg/Productdetails/filledstar.svg';
// import smallimg from '../../images/Productdetails/smallimg2.png';
import smallimg from '../../images/Productdetails/bigimg1.png';
import bigimg from '../../images/Productdetails/bigimg3.png';
import bigimg2 from '../../svg/Productdetails/bigimg3.svg';
import BreadCrum from '../../component/BreadCrum'


const reviewsData = [
    {
        id: 1,
        userName: 'sonali kumar',
        reviewDays: '2 Days Ago',
        rating: "4.0",
        reviewText:
            'Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag. Portfolio metoden poment mogesade, ossa. Revönde sygt yliga. Revönde sygt yliga med ljudöra Pohag.',
        likes: 31,
        dislikes: 6,
    },

    {
        id: 1,
        userName: 'sonali kumar',
        reviewDays: '2 Days Ago',
        rating: "4.0",
        reviewText:
            'Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag. Portfolio metoden poment mogesade, ossa. Revönde sygt yliga. Revönde sygt yliga med ljudöra Pohag.',
        likes: 31,
        dislikes: 6,
    },

    {
        id: 1,
        userName: 'sonali kumar',
        reviewDays: '2 Days Ago',
        rating: "4.0",
        reviewText:
            'Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag. Portfolio metoden poment mogesade, ossa. Revönde sygt yliga. Revönde sygt yliga med ljudöra Pohag.',
        likes: 31,
        dislikes: 6,
    },

    {
        id: 1,
        userName: 'sonali kumar',
        reviewDays: '2 Days Ago',
        rating: "4.0",
        reviewText:
            'Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag. Portfolio metoden poment mogesade, ossa. Revönde sygt yliga. Revönde sygt yliga med ljudöra Pohag.',
        likes: 31,
        dislikes: 6,
    },


    // Add more review objects as needed
];

export default function ProductdetailsPage() {
    const [description, setDescription] = useState(true);
    const [additionalInfo, setAdditionalInfo] = useState(false);
    const [review, setReview] = useState(false);
    // const [bigimg, setBigimg] = useState('../../images/Productdetails/bigimg3.png'); 

    const handleDescription = () => {
        setDescription(true);
        setAdditionalInfo(false);
        setReview(false);
    }

    const handleAdditionalInfo = () => {
        setDescription(false);
        setAdditionalInfo(true);
        setReview(false);
    }

    const handleReview = () => {
        setDescription(false);
        setAdditionalInfo(false);
        setReview(true);
    }

    const handleimg = (newBigImgPath) => {
        // console.log('Current Big Image Path:', bigimg);
        // console.log('New Image Path:', newBigImgPath);
        // console.log('Current Big Image Path:', bigimg);
        // if (newBigImgPath !== bigimg) {
        //     setBigimg(newBigImgPath);
        //   }
    }

    return (
        <Layout >
            <BreadCrum previousPage="Edible Oil" currentPageName="Sunflower Oil" />
            <div>
                <div className='pdp-main-container'>
                    {/* <div className='pdp-header-container'>
                        <span className='home-txt'>Home /</span>
                        <span className='edible-txt'> Edible Oil /</span>
                        <span className='sunflower-oil'> Sunflower Oil</span>
                    </div> */}

                    <div className='pdp-sub-container'>
                        <div className='pdp-image-man-container'>
                            <div className='pdp-img' >
                                <img src={bigimg} alt='img1' className='bigimg' />
                                <img src={heart} alt='alt' className='heart-icon' />
                            </div>
                            <div className='pdp-multi-images'>
                                <img src={smallimg} alt='img4' onClick={handleimg('../../images/Productdetails/bigimg1.png')} />
                                <img src={smallimg} alt='img4' onClick={handleimg('../../images/Productdetails/bigimg1.png')} />
                                <img src={smallimg} alt='img4' onClick={handleimg('../../images/Productdetails/bigimg1.png')} />
                                <img src={smallimg} alt='img4' onClick={handleimg('../../images/Productdetails/bigimg1.png')} />
                            </div>
                        </div>

                        <div className='pdp-mid-container'>
                            <h4 className='sunflower-heading'>Flash Sunflower Oil (990ml)</h4>
                            <div className='rupee-review'>
                                <div className='rates'>
                                    <span className='rates-txt'>₹210</span>
                                    <span className='rates-txt-cancel'>₹350</span>
                                </div>
                                <div>
                                    <span>
                                        <img src={filledstar} alt='alt' class="filled-images1" />
                                        <img src={filledstar} alt='alt' class="filled-images1" />
                                        <img src={filledstar} alt='alt' class="filled-images1" />
                                        <img src={filledstar} alt='alt' class="filled-images1" />
                                        <img src={filledstar} alt='alt' class="filled-images1" />
                                    </span>
                                    <span>  (4.0)</span>
                                </div>
                            </div>
                            <p className='pdp-details-txt'>
                                Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa.
                                Revönde sygt yliga med ljudöra. Pohag. Portfolio metoden poment mogesade, ossa. Revönde sygt yliga
                            </p>

                            <div className='pdp-size-container'>
                                <p>
                                    <span className='size-txt-pdp'>Size:</span> <span className='pdp-selected-size'>990ml</span>
                                </p>
                                <div className='size-in-ml'>
                                    <span>990ml</span>
                                    <span>1.5ml</span>
                                    <span>2ml</span>
                                    <span>5ml</span>
                                </div>
                            </div>

                            <div className='quantity-caontainer'>
                                <span className='quantity-txt'>Quantity: </span>
                                <div className='increase-decrease-btn'>
                                    <span className='decrement'>-</span > <span className='counter'>1</span> <span className='increamnet'>+</span>
                                </div>
                            </div>

                            <div className='check-pin'>
                                <p className='enter-pincode'>enter your pincode:</p>
                                <input type='text' placeholder='Enter Pin' />
                                <button>Change</button>
                            </div>

                            <div className='pdp-btns'>
                                <button className='add-to-cart-btn'>Add To cart</button>
                                <button className='buy-now-btn'>Buy Now</button>
                            </div>
                        </div>

                        <div className='pdp-last-container'>
                            <div className='pdp-last-container-box1'>
                                <div className='pdp-last-container-box-inner'>
                                    <img src={icon1} alt='alt' className='icons-last-container' />
                                    <p className='box1-txt'>delivery</p>
                                    <p className='box1-info'>the item is usually delivered within</p>
                                    <p className='box1-info'> 5 days of order</p>
                                </div>
                            </div>
                            <div className='pdp-last-container-box2'>
                                <div className='pdp-last-container-box-inner'>
                                    <img src={icon2} alt='alt' className='icons-last-container' />
                                    <p className='box1-txt'>returns</p>
                                    <p className='box1-info'>the item can be returned within 5</p>
                                    <p className='box1-info'> days after delivery</p>
                                </div>
                            </div>
                            <div className='pdp-last-container-box3'>
                                <div className='pdp-last-container-box-inner'>
                                    <img src={icon3} alt='alt' className='icons-last-container' />
                                    <p className='box1-txt'>refunds</p>
                                    <p className='box1-info'>the amount is usually refunded within </p>
                                    <p className='box1-info'>2 days after picking  up the item</p>
                                </div>
                            </div>
                            <div className='pdp-last-container-box4'>

                                <div className='pdp-last-container-box-inner'>
                                    <img src={icon4} alt='alt' className='icons-last-container' />
                                    <p className='box1-txt box4-underline'>secured checkouts</p>
                                    <div className='img-payments'>
                                        <img src={redyellow} alt='alt' className='icons-last-container1 hdfc' />
                                        <img src={hdfcbank} alt='alt' className='icons-last-container1 redblue' />
                                        <img src={redblue} alt='alt' className='icons-last-container1 redyellow' />
                                        <img src={googlepay} alt='alt' className='icons-last-container1 gpay' />
                                        <img src={visa} alt='alt' className='icons-last-container1 visa' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='pdp-tabs-container'>
                        <span className={`${description ? 'description-txt-underline' : 'description-txt'}`} onClick={handleDescription}>Description </span><spa> | </spa>
                        <span className={`${additionalInfo ? 'additional-info-txt-underline' : 'additional-info-txt'}`} onClick={handleAdditionalInfo}> Additional Information </span><spa> | </spa>
                        <span className={`${review ? 'reviews-txt-underline' : 'reviews-txt'}`} onClick={handleReview}> Reviews</span>
                    </div>

                    <div className='pdp-description-container'>

                        {description && (<div>
                            <div className='pdp-sub-description-container'>
                                <p className='key-title'>key specification</p>
                                <ul className='pdp-list-description'>
                                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                    <li>Lorem Ipsum has been the industry’s standard dummy text ever since.</li>
                                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                    <li>Lorem Ipsum has been the industry’s standard dummy text ever since.</li>
                                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                    <li>Lorem Ipsum has been the industry’s standard dummy text ever since.</li>
                                </ul>
                            </div>

                            <div>
                                <p className='key-title'>More Details</p>
                                <ul className='pdp-list-description'>
                                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                    <li>Lorem Ipsum has been the industry’s standard dummy text ever since.</li>
                                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                    <li>Lorem Ipsum has been the industry’s standard dummy text ever since.</li>
                                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                    <li>Lorem Ipsum has been the industry’s standard dummy text ever since.</li>
                                </ul>
                            </div>
                        </div>)}

                        {review && (<div>
                            <div>
                                <span className='product-reviews'>Product Reviews</span>
                                <span className='total-review-numbver'> (23) </span>
                            </div>
                            <div className='review-bar'>
                                <div className='img-review-container'>
                                    <img src={bigimg2} alt='alt' className='img-review' />
                                </div>
                                <div className='review-right'>
                                    <div className='reviewed-singlestar-txt1'>
                                        <span className='review-number'>4.5</span>
                                        <span className='filled-images'>
                                            <img src={filledstar} alt='alt' className='review-filled-images1' />
                                            <img src={filledstar} alt='alt' className='review-filled-images1' />
                                            <img src={filledstar} alt='alt' className='review-filled-images1' />
                                            <img src={filledstar} alt='alt' className='review-filled-images1' />
                                            <img src={filledstar} alt='alt' className='review-filled-images1' />
                                        </span>
                                    </div>
                                    <p className='rating-based'>based on 23 ratings</p>
                                    <div className='review-bar-outer'>
                                        <span className='review-bar-inner'>5 stars</span>
                                        <div className='review-graph'></div>
                                    </div>
                                    <div className='review-bar-outer'>
                                        <span className='review-bar-inner'>4 stars</span>
                                        <div className='review-graph'></div>
                                    </div>
                                    <div className='review-bar-outer'>
                                        <span className='review-bar-inner'>3 stars</span>
                                        <div className='review-graph'></div>
                                    </div>
                                    <div className='review-bar-outer'>
                                        <span className='review-bar-inner'>2 stars</span>
                                        <div className='review-graph'></div>
                                    </div>
                                    <div className='review-bar-outer'>
                                        <span className='review-bar-inner'>1 stars</span>
                                        <div className='review-graph'></div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                {reviewsData.map((review, index) => (
                                    <div className='one-users-review' key={index}>
                                        <div className='review-details'>
                                            <p>
                                                <span className='user-name'>{review.userName}</span>
                                                <span className='review-days'>{review.reviewDays}</span>
                                            </p>
                                            <p className='reviewed-singlestar-txt'>
                                                <span className='review-number'>{review.rating}</span>
                                                <span className='filled-images'>
                                                    {Array.from({ length: Math.round(review.rating) }, (_, index) => (
                                                        <img key={index} src={filledstar} alt='alt' class="review-filled-images1-single" />
                                                    ))}
                                                </span>
                                            </p>
                                        </div>
                                        <p className='review-txt'>{review.reviewText}</p>
                                        <div className='details-pd'>
                                            <div className='increamnet-number'>
                                                <span>{review.likes}</span> <img src={like} alt='alt' className='like-img' />
                                            </div>
                                            <div className='decreament-number'>
                                                <span> {review.dislikes} </span><img src={dislike} alt='alt' className='like-img' />
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>)}

                    </div>
                </div>
            </div>
        </Layout>
    )
}
