import React from "react";
import "../styles/privacyPolicy.css";
import Layout from "../component/layout";
import BreadCrum from "../component/BreadCrum";

const widthOutput = window.screen.width;
const headerJHeadingFontSize = (2.2 / 100) * widthOutput;
const titleHeaderFontSize = (1.6 / 100) * widthOutput;

const headerHeadingStyles = {
    fontSize: `${headerJHeadingFontSize}px`
}

export const PrivacyPolicy = () => {
    return (
        <Layout>
            <BreadCrum currentPageName="Privacy policy" />
            <div className="mainDiv_PrivacyPolicy">
                <div className="innerMainDiv_PrivacyPolicy">
                    <div className="headerWithCOntentMainDiv_PrivacyPolicy">
                        <div className="headerHeading_PrivacyPolicy">
                            <h1 style={headerHeadingStyles}>Privacy policy</h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}><a target="blank" href="https://mysunpure.in/">www.mysunpure.in</a> is an electronic commerce platform which is predominantly used by customers to facilitate electronic commerce to purchase the listed Products .The purchase of Products may involve collection of personal information of individuals and MK Agrotech recognizes the importance of privacy as well as the importance of maintaining the confidentiality of such personal information. This Privacy Policy applies to all products and services provided by us and sets out how we may collect, use and disclose information in relation to users of the website.<br></br>
                                You may use our services and products via a mobile device either through mobile applications or mobile optimized websites. This Privacy Policy also applies to such use of our services and products.</h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_PrivacyPolicy">
                        <div className="termsOfUseTitleMainDiv_PrivacyPolicy">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>terms of use</h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>Your use of this portal is also subject to the Terms of Use posted on this web-portal ("Terms of Use").</h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_PrivacyPolicy">
                        <div className="termsOfUseTitleMainDiv_PrivacyPolicy">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>INFORMATION COVERED BY THIS PRIVACY POLICY</h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>This Privacy Policy covers the “Personal Information” we collect through our Sites. “Personal Information” is information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with you personally, either alone or in combination with other information available to us. Examples of Personal Information include your name, contact details, credit card information, information you provide when you create an account or a personal profile on one of our Sites and information about the pages you have viewed and the path you have taken through our Sites.
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_PrivacyPolicy">
                        <div className="termsOfUseTitleMainDiv_PrivacyPolicy">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>INFORMATION WE COLLECT</h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>We may collect Personal information on this Website voluntarily submitted by the user, including information we collect while performing business transactions and information we collect from other sources (as permitted by law) for the performance of a contract with you or where it is necessary for our legitimate interests of operating, protecting and improving our business.
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_PrivacyPolicy">
                        <div className="termsOfUseTitleMainDiv_PrivacyPolicy">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>INFORMATION COLLECTED DIRECTLY</h1>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`,
                                paddingTop:"2rem"
                            }}>We may collect information from you directly when you provide us with personal information, e.g. when you register for prize draws or competitions, sign up to receive information, use applications, buy a product or service from us, fill out a survey, or make a comment or enquiry. The following are examples of information we may collect directly:
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>a) Name, email address, postal address
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>b) Username and password
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>c) Phone number or mobile number
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>d) Photo identity proof
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>e) Date of birth
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>f) Demographic information and service history
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>g) Payment instrument details (such as a credit card information, credit history, securitycodes)
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>h) Future communication preferences
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>i) Other household information about you and your family such as gender or product usepreferences and/or behaviors.
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>j) Health information, such as health information related to product usage and medicalhistory.
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>k) Any information necessary to perform a sales transaction.
                            </h4>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_PrivacyPolicy">
                        <div className="termsOfUseTitleMainDiv_PrivacyPolicy">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>INFORMATION COLLECTED AUTOMATICALLY</h1>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`,
                                paddingTop:"2rem"
                            }}>We (and third party service providers acting on our behalf) may use cookies and other tools (such as web analytic tools, pixel tags, web beacon, javascript etc) to automatically collect information about you when you use this website or M K Agrotech Private Limited Sites, subject to the terms of this Privacy Notice and applicable data laws and regulations. The types of information collected automatically mayinclude but not limited to such as below
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>a) details of the web pages you have viewed
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>b) information about the type of browser you use
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>c) the hyperlinks you have clicked
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>d) your IP address
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>e) your username, profile picture, gender, networks and any other information you choose to share when using Third Party Sites (such as when you use the “Like” functionality on Facebook or the +1 functionality on Google+)
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>f) the websites you visited before arriving at M K Agrotech Private Limited Website or this site.
                            </h4>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_PrivacyPolicy">
                        <div className="termsOfUseTitleMainDiv_PrivacyPolicy">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>HOW WE USE THIS INFORMATION</h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>Personal information collected from data subjects listed in this portal will only be kept in order to conduct the required business, and for as long as the requirement was deemed at the time of data collection. The information collected may be used for the following activities:
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_PrivacyPolicy">
                        <div className="termsOfUseTitleMainDiv_PrivacyPolicy">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>ACCESS TO INFORMATION BY RELEVANT PERSONS</h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>All personal information must be accurate, complete, and relevant for the business purposes of collection of such information, and necessary only to facilitate those purposes. Your access to and use of this web-portal and its contents (the "Portal") is subject to the terms and conditions of this Privacy Policy, and all applicable laws. By accessing and using this Portal, you (the "User" or "you") accept and agree to these without any limitation or qualification.
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_PrivacyPolicy">
                        <div className="termsOfUseTitleMainDiv_PrivacyPolicy">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>INFORMATION SHARING</h1>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`,
                                paddingTop:"2rem"
                            }}>We (and third party service providers acting on our behalf) may use cookies and other tools (such as web analytic tools, pixel tags, web beacon, javascript etc) to automatically collect information about you when you use this website or M K Agrotech Private Limited Sites, subject to the terms of this Privacy Notice and applicable data laws and regulations. The types of information collected automatically mayinclude but not limited to such as below
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>a)Third-party service providers – We may share your information with outside vendors that we use for a variety of purposes, such as to send you communications via emails, messages or tele-call to inform you about our products that may be of interest to you, push notifications to your mobile device on our behalf, provide voice recognition services to process your spoken queries and questions, help us analyze use of our Services, and process and collect payments. Some of our products, services and databases are hosted by third party hosting services providers. We also may use vendors for other projects, such as conducting surveys or organizing sweepstakes for us. We may share information about you with these vendors only to enable them to perform their services.
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>b) Legal action and obligations - We may disclose Personal information:
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>c) Social Networks. If you interact with social media features on our Services, such asthe Facebook Like button, or use your social media credentials to log-in orpost content, these features may collect information about your use of theServices, as well as post information about your activities on the social mediaservice. Your interactions with social media companies are governed by theirprivacy policies.
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>d) To our subsidiaries and affiliates, which are entities under common ownership orcontrol of our ultimate parent company M K Agrotech Private Limited.
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>e) To contractors, advertisers/service providers and other third-parties whom we useto support our business (e.g. Distributors, food delivery providers, e-commercecompanies) and who are bound by contractual obligations to keep personal informationconfidential and use it only for the purposes for which we disclose it to them.
                            </h4>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_PrivacyPolicy">
                        <div className="termsOfUseTitleMainDiv_PrivacyPolicy">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>INFORMATION security</h1>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`,
                                paddingTop:"2rem"
                            }}>We have implemented appropriate physical, electronic, and managerial procedures to[ safeguard and help prevent unauthorized access to your information and to maintain data security. These safeguards take into account the sensitivity of the information that we collect, process and store and the current state of technology. We follow generally accepted industry standards to protect the Personal information submitted to us, both during transmission and once we receive it. The third-party service providers with respect to payment gatewayand payment processing are all validated as compliant with the payment card industry standard (generally referred to as PCI compliant service providers).
                                We assume no liability or responsibility for disclosure of your information due to errors in transmission, unauthorized third-party access, or other causes beyond our control. You play an important role in keeping your personal information secure. You should not share your username, password, or other security information for your account with anyone. If we receive instructions using your username and password, we will consider that you have authorized the instructions
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>The Services may contain links to third-party websites. Your use of these features may result in the collection, processing or sharing of information about you, depending on the feature. Please be aware that we are not responsible for the content or privacy practices of other websites or services which may be linked on our services. We do not endorse or make any representations about third-party websites or services. Our Privacy Policy does not cover the information you choose to provide to or that is collected by these third parties. We strongly encourage you to read such third parties' privacy policies.
                            </h4>

                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_PrivacyPolicy">
                        <div className="termsOfUseTitleMainDiv_PrivacyPolicy">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>CHANGES TO OUR PRIVACY NOTICE</h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>We will post changes to this privacy notice and update the effective date when this Privacy Policy is updated. We may modify this Privacy Policy at any time, in our sole discretion and all modifications will be effective immediately upon our posting of the modifications. Continued use of website, following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_PrivacyPolicy">
                        <div className="termsOfUseTitleMainDiv_PrivacyPolicy">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>CONTACT US</h1>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`,
                                paddingTop:"2rem"
                            }}>Company Address :M K AGROTECH PVT LTD, (SUNPURE),
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>Kaveri layout, M.B. Road, Srirangapatna, Karnataka 571438
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>Contacttelephone number: +91 8236 252348
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>[from: 9:30 AMto 5:30 PM from Monday to Friday except Public, National & Festival Holidays]
                            </h4>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_PrivacyPolicy">
                        <div className="termsOfUseTitleMainDiv_PrivacyPolicy">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>CONSENT</h1>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`,
                                paddingTop:"2rem"
                            }}>I acknowledge and agree to the terms contained in this data privacy notice, which shall be effective for the entire duration of my relationship with M K AgrotechPrivate Limited as a user ofthis website and until M K Agrotech Private Limited retains records of the captured Personal information as specified above.
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>By using MK Agrotech owned site, you are consenting to the relevant M K Agrotech Private Limited collecting, using and disclosing your Personal information in accordance with this PrivacyNotice. If you do not agree to the collection, use and disclosure of your Personal information in this way, you are free not to use this website or otherwise provide M K Agrotech Private Limited with your Personal information.
                            </h4>
                        </div>
                    </div>
                    <div className="headerWithCOntentMainDiv_PrivacyPolicy">
                        <div className="headerHeading_PrivacyPolicy">
                            <h1 style={headerHeadingStyles}>shipping policy and delivery charges</h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>How much are the delivery charges? We charge Rs. 60 (incl. of taxes) for 7km asshipping charges for order value lesser than Rs. 899.
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_PrivacyPolicy">
                        <div className="termsOfUseTitleMainDiv_PrivacyPolicy">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>HOW SURE AM I THAT THE PRODUCTS DELIVERED WILL BE FRESH?</h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>All items delivered by us come with a manufacturing date & an expiry date. If you notice any damage to the packaging, wrong product shipped in the order, at the time of delivery, you can request return through our e-commerce website.
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_PrivacyPolicy">
                        <div className="termsOfUseTitleMainDiv_PrivacyPolicy">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>What if I am not home at the time of order delivery?</h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>Our logistics vendors, will make a total of 2 attempts to deliver at your given delivery address. So if you miss your delivery on firstattempt you can always grab it at second.
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_PrivacyPolicy">
                        <div className="termsOfUseTitleMainDiv_PrivacyPolicy">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>IF I AM NOT HOME, CAN ANYONE ELSE RECEIVE THE ORDER?</h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>In case the person who placed the order is not available at the delivery address, any person available at the delivery address(residential or otherwise) can receive the Products. This will be considered as order delivered.
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_PrivacyPolicy">
                        <div className="termsOfUseTitleMainDiv_PrivacyPolicy">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>DO YOU DELIVER TO ALL CITIES?</h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>We currently deliver within the Mysore within 3-8hr (Same day delivery If order placed before 4pm)
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_PrivacyPolicy">
                        <div className="termsOfUseTitleMainDiv_PrivacyPolicy">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>WHAT IS THE ESTIMATED SHIPPING TIME? HOW WILL I KNOW WHEN THE ORDER IS SHIPPED?</h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>With every order, you will receive an email containing the details of the order placed by you. Once we ship the item(s), you will receive another email containing the shipping details. Usually it takes about 3-4 hr for the products to be Dispatched.
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_PrivacyPolicy">
                        <div className="termsOfUseTitleMainDiv_PrivacyPolicy">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>ONCE THE ORDER IS MADE, CAN THE ORDER BE CANCELLED?</h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>The order placed by the Customer /Guest can be cancelled only before the order is shipped Orde . The amount will be refunded to you in the form of coupon code or store credit within 15 days. For orders that have already been shipped, there will be no refunds made except for damage of packaging, and wrong product shipped. What about any hidden costs (sales tax, octroi etc.)?<br></br>
                            VAT is charged on the order which you will have a chance to review in your Shopping Cart. We do not charge any other addtional charges.
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_PrivacyPolicy">
                        <div className="termsOfUseTitleMainDiv_PrivacyPolicy">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>DOES MK AGROTECH PVT LTD DELIVER INTERNATIONALLY?
                            </h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>MK Agrotech Pvt Ltd doesn't deliver items internationally. You are more than welcome to make your purchases on our site from anywhere in the world, but you'll have to ensure the Delivery Address is within Mysore , Bangalore , Mumbai.
                            VAT is charged on the order which you will have a chance to review in your Shopping Cart. We do not charge any other addtional charges.
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_PrivacyPolicy">
                        <div className="termsOfUseTitleMainDiv_PrivacyPolicy">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>CAN I CANCEL MY ORDER AFTER PLACING IT?
                            </h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>The order once placed cannot be cancelled after it has been shipped. In case of cancellations, the amount will be refunded to you in the form of coupon code or store credit within 7 to 10 business days.
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_PrivacyPolicy">
                        <div className="termsOfUseTitleMainDiv_PrivacyPolicy">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>CAN I CANCEL MY ORDER AFTER PLACING IT?
                            </h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>The order once placed cannot be cancelled after it has been shipped. In case of cancellations, the amount will be refunded to you in the form of coupon code or store credit within 7 to 10 business days.
                            </h3>
                        </div>
                    </div>
                    <div className="headerWithCOntentMainDiv_PrivacyPolicy">
                        <div className="headerHeading_PrivacyPolicy">
                            <h1 style={headerHeadingStyles}>cancellation, return and exchange</h1>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_PrivacyPolicy">
                        <div className="termsOfUseTitleMainDiv_PrivacyPolicy">
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`,
                                paddingTop:"2rem"
                            }}>1. An order placed and confirmed cannot be cancelled.
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>2. You are entitled to a 100% replacement of order in case you received something else than what you ordered or your order reached you in a distorted manner.
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>3. Replacement process must be initiated within 24 hours from the time of receipt of order by the User. The Product must be in an unused state before seeking for a replacement and should be accompanied by a copy of the original receipt or invoice issued at the time of sale of the Product
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>4. Any request for replacement will be accepted only after inspection of the Product. Once the replacement request is placed with the customer service, the logistics partner will arrange for a pickup of the Product from the address provided at the time of placing the order. Once the Product is received, it takes about 1-2 working day for inspection team to confirm on the replacement. On confirmation of the genuine need for replacement of the Product, we shall replace the desired Product with the User in 3 working days.
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}