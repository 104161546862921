



import '../../styles/ProductLanding/ProductLanding.css';
import React, { useEffect, useState } from 'react';
import downarrow from '../../svg/ProductLanding/downarrow.svg';
import pl_img from '../../svg/ProductLanding/pl_img.svg';
import singlestar from '../../svg/ProductLanding/singlestar.svg';
import lockicon from '../../svg/ProductLanding/lockicon.svg';
import Layout from '../../component/layout';
import line from '../../svg/ProductLanding/line.svg';
import first_black_line from '../../svg/ProductLanding/first_black_line.svg';
import cancelicon from '../../svg/ProductLanding/cancelicon.svg';
import horizontal_line from '../../svg/ProductLanding/horizontal_line.svg';
import like from '../../svg/ProductLanding/like.svg';
// import eye from '../../svg/ProductLanding/eye.svg';
import quickview from '../../svg/quickViewIcon.svg';
import filtered_img from '../../svg/ProductLanding/filtered_img.svg';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const products = [
  {
    id: 1,
    name: 'Fresh Sunflower Oil 990ml',
    rating: 5,
    price: 210,
    discountedPrice: 310,
    imgSrc: pl_img,
  },
  {
    id: 1,
    name: 'Fresh Sunflower Oil 990ml',
    rating: 5,
    price: 210,
    discountedPrice: 310,
    imgSrc: pl_img,
  },
  {
    id: 1,
    name: 'Fresh Sunflower Oil 990ml',
    rating: 5,
    price: 210,
    discountedPrice: 310,
    imgSrc: pl_img,
  },

  {
    id: 1,
    name: 'Fresh Sunflower Oil 990ml',
    rating: 5,
    price: 210,
    discountedPrice: 310,
    imgSrc: pl_img,
  },

  {
    id: 1,
    name: 'Fresh Sunflower Oil 990ml',
    rating: 5,
    price: 210,
    discountedPrice: 310,
    imgSrc: pl_img,
  },

  {
    id: 1,
    name: 'Fresh Sunflower Oil 990ml',
    rating: 5,
    price: 210,
    discountedPrice: 310,
    imgSrc: pl_img,
  },
  // Add more products as needed
];


export default function ProductLanding() {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCategoriesProduct, setSelectedCategoriesProduct] = useState([]);
  const [selectedCategoriesPrice, setSelectedCategoriesPrice] = useState([]);
  const [selectedCategoriesAvailabilty, setSelectedCategoriesAvailabilty] = useState([]);
  const [selectedStock, setSelectedStock] = useState(null);
  const [rangeValues, setRangeValues] = useState([1, 500]);
  const [filterVisible, setFilterVisible] = useState(true);
  const [value, setValue] = useState([20, 37]);
  const [showcatogies, SetShowCategories] = useState(false);
  const [showPriceRange, setShowPriceRange] = useState(false);
  const [ShowAvailabilty, setShowAvailabilty] = useState(false);
  const isDesktopView = window.innerWidth >= 1024;


  useEffect(() => {
    const ele = document.querySelector('.buble');
    if (ele) {
      ele.style.left = `${Number(value / 4)}px`;
    }
  })


  const categoriesData = [
    { name: 'Atta', count: 15 },
    { name: 'edible oil', count: 19 },
    { name: 'salt', count: 11 },
    { name: 'spices', count: 22 },
  ];

  const stocks = [
    { stock: 'In Stock', count: (15) },
    { stock: 'Out Of Stock', count: (19) }
  ];

  const handleCheckboxChange = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter(item => item !== category));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const handleRadioChange = (selectedStock) => {
    setSelectedStock(selectedStock);
  };

  const clearAllFilters = () => {
    setSelectedCategories([]);
  }

  const clearAllCategories = () => {
    setSelectedCategoriesProduct([]);
  }

  const clearAllPrice = () => {
    setSelectedCategoriesPrice([]);
  }

  const clearAllAvailability = () => {
    setSelectedCategoriesAvailabilty([]);
  }

  function valuetext(value) {
    return `${value}°C`;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleInputChange = (index, value) => {
    const newValues = [...rangeValues];
    newValues[index] = value;
    setRangeValues(newValues);
  };

  const handlePDPFilter = () => {
    setFilterVisible(!filterVisible);
  }

  const handleCatogaries = () => {
    SetShowCategories(!showcatogies);
    console.log('handleCatogaries', showcatogies);
  }

  const handlePriceRange = () => {
    setShowPriceRange(!showPriceRange);
  }

  const handleAvailability = () => {
    setShowAvailabilty(!ShowAvailabilty);
    console.log('handleAvailability', ShowAvailabilty);
  }

  return (
    <Layout>
      <div className='pl-main-container'>
        <div className='pl-header-container'>
          <span className='home-txt'>Home / </span> <span className='atta-txt'> Atta</span>
        </div>
        <div className='pl-sub-container'>
          <div className={`${filterVisible ? 'pl-left-container' : 'pl-left-container-active'}`}>
            <div className='main-filtered'>
              <div className='pl-filter'>
                <p><span className='pl-filter-txt'>Filter </span><span className='pl-filter-selected'>
                  ({selectedCategories.length} selected) </span></p>
                <p><span className='pl-clear-all' onClick={clearAllFilters}>clear all</span></p>
              </div>
              <div className='selected-items-filtered'>
                {selectedStock && (
                  <div className='filtered-product'>
                    <span className='filtered-product-name'>{selectedStock}</span>
                    <span className='cancel-icon' onClick={() => handleRadioChange(null)}>
                      <img src={cancelicon} alt='alt' />
                    </span>
                  </div>
                )}

                {selectedCategories.map((category, index) => (
                  <div key={index} className='filtered-product'>
                    <span className='filtered-product-name'>{category}</span>
                    <span className='cancel-icon' onClick={() => handleCheckboxChange(category)}>
                      <img src={cancelicon} alt='alt' />
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className='categories-content'>
              <div className='pl-product-filters'>
                <p><span className='pl-categories-txt'>categories </span>
                  <img className='downarrow-img' src="/static/media/downarrow.6185df0e97b30b766399be9fe25c7b33.svg" alt="alt" onClick={() => handleCatogaries()}></img>
                </p>
                <p><span className='pl-clear-all' onClick={clearAllCategories}>clear</span></p>
              </div>

              {showcatogies ?
                (<div className='catergories-checkbox-container'>
                  {categoriesData.map((category, index) => (
                    <label key={index} className="custom-checkbox">
                      <span className='categories-name'>{`${category.name} (${category.count})`}</span>
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange(category.name)}
                        checked={selectedCategories.includes(category.name)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  ))}
                </div>)
                :
                (
                  <div className={`${isDesktopView ? 'catergories-checkbox-container' : 'mobile-catergories-checkbox-container'}`}>
                    {categoriesData.map((category, index) => (
                      <label key={index} className="custom-checkbox">
                        <span className='categories-name'>{`${category.name} (${category.count})`}</span>
                        <input
                          type="checkbox"
                          onChange={() => handleCheckboxChange(category.name)}
                          checked={selectedCategories.includes(category.name)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    ))}
                  </div>)
              }
            </div>
            <div className='categories-content'>
              <div className='pl-product-filters'>
                <p><span className='pl-categories-txt'>price range </span>
                  <img className='downarrow-img' src="/static/media/downarrow.6185df0e97b30b766399be9fe25c7b33.svg" alt="alt" onClick={() => handlePriceRange()}></img>
                </p>
                <p><span className='pl-clear-all' onClick={clearAllPrice}>clear</span></p>
              </div>
              {showPriceRange ?
                (<div className='catergories-checkbox-container'>
                  <p><span className='price-content'>the highest price is 10,000*</span></p>

                  <div className="slider-parent">
                    <div className='first-verticle-point'>
                      <span className='first-point'></span>
                      <Box sx={{ width: 300 }}>
                        <Slider
                          getAriaLabel={() => 'Temperature range'}
                          value={value}
                          onChange={handleChange}
                          valueLabelDisplay="auto"
                          getAriaValueText={valuetext}
                        />
                      </Box>
                    </div>

                    <div className="input-number-container">
                      <div className='input-boxes'>
                        <p className='from-txt'>From</p>
                        <div className='from-container'>
                          <span className='fromrupee'>₹</span>
                          <input
                            type='number'
                            name='from-num'
                            className='from-num'
                            placeholder='0'
                            value={rangeValues[0]}
                            onChange={(e) => handleInputChange(0, parseInt(e.target.value))}
                          />
                        </div>
                      </div>

                      <div className='input-boxes'>
                        <p className='to-txt'>To</p>
                        <div className='to-container'>
                          <span className='torupee'>₹</span>
                          <input
                            type='number'
                            name='to-num'
                            className='to-num'
                            placeholder='0'
                            value={rangeValues[1]}
                            onChange={(e) => handleInputChange(1, parseInt(e.target.value))}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>)
                : (<div className={`${isDesktopView ? 'catergories-checkbox-container' : 'mobile-catergories-checkbox-container'}`}>
                  <p><span className='price-content'>the highest price is 10,000*</span></p>

                  <div className="slider-parent">
                    <div className='first-verticle-point'>
                      <span className='first-point'></span>
                      <Box sx={{ width: 300 }}>
                        <Slider
                          getAriaLabel={() => 'Temperature range'}
                          value={value}
                          onChange={handleChange}
                          valueLabelDisplay="auto"
                          getAriaValueText={valuetext}
                        />
                      </Box>
                    </div>

                    <div className="input-number-container">
                      <div className='input-boxes'>
                        <p className='from-txt'>From</p>
                        <div className='from-container'>
                          <span className='fromrupee'>₹</span>
                          <input
                            type='number'
                            name='from-num'
                            className='from-num'
                            placeholder='0'
                            value={rangeValues[0]}
                            onChange={(e) => handleInputChange(0, parseInt(e.target.value))}
                          />
                        </div>
                      </div>

                      <div className='input-boxes'>
                        <p className='to-txt'>To</p>
                        <div className='to-container'>
                          <span className='torupee'>₹</span>
                          <input
                            type='number'
                            name='to-num'
                            className='to-num'
                            placeholder='0'
                            value={rangeValues[1]}
                            onChange={(e) => handleInputChange(1, parseInt(e.target.value))}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>)
              }
            </div>

            <div className='categories-content'>
              <div className='pl-product-filters'>
                <p><span className='pl-categories-txt'>availability </span>
                  <img className='downarrow-img' src="/static/media/downarrow.6185df0e97b30b766399be9fe25c7b33.svg" alt="alt" onClick={() => handleAvailability()}></img>
                </p>
                <p><span className='pl-clear-all' onClick={clearAllAvailability}>clear</span></p>
              </div>

              {
                ShowAvailabilty ?
                (<div className='catergories-checkbox-container'>
                  {stocks.map((stock, index) => (
                    <label key={index} className="custom-checkbox">
                      <span className='categories-name'>{`${stock.stock} (${stock.count})`}</span>
                      <input
                        type="radio"
                        onChange={() => handleRadioChange(stock.stock)}
                        checked={selectedStock === stock.stock}
                      />
                      <span className="checkmark"></span>
                    </label>
                  ))}
                </div>):
                (<div className={`${isDesktopView ? 'catergories-checkbox-container' : 'mobile-catergories-checkbox-container'}`}>
                {stocks.map((stock, index) => (
                  <label key={index} className="custom-checkbox">
                    <span className='categories-name'>{`${stock.stock} (${stock.count})`}</span>
                    <input
                      type="radio"
                      onChange={() => handleRadioChange(stock.stock)}
                      checked={selectedStock === stock.stock}
                    />
                    <span className="checkmark"></span>
                  </label>
                ))}
              </div>)
              }
            </div>


          </div>
          <div className='horizontal-line-container'><img src={horizontal_line} alt='alt' className='horizontal-line' /></div>
          <div className='pl-right-container'>
            <div className='pl-products'>
              <p className='atta-txt'>
                <span className='atta'>Atta </span><span className='pl-item-selected'>(15)</span>
              </p>
              <p className='sort-by'>
                <span className='pl-sortby'>Sort By </span>
                <span className='downarrow-img' style={{ paddingLeft: "10px" }}> <img src={downarrow} alt='alt' /></span>
                <div className='filter-container' onClick={() => handlePDPFilter(filterVisible)}>
                  <p className='filter-txt'> Filtere</p>
                  <img className='toggle-filter-btn'
                    src={filtered_img} alt='alt'
                  />
                </div>
              </p>
            </div>
            <img src={line} alt='alt' className='line' />
            <div className='product-main-container'>
              {products.map((product) => (
                <div key={product.id} className='pl-single-products-container'>
                  <div className='pl-img-container'>
                    <img src={product.imgSrc} alt={" "} className='product-img' />
                    <img src={like} alt='alt' className='like-icon' />
                    <div className='eye-icon-container'>
                      <img src={quickview} alt='alt' className='eye-icon ' />
                    </div>
                    <img src={quickview} alt='alt' className='eye-quickview' />
                  </div>
                  <div className='pl-product-info'>
                    <p className='pl-product-name'>{product.name}</p>
                    <p className='singlestar-filled'>
                      {[...Array(product.rating)].map((_, index) => (
                        <img key={index} src={singlestar} alt='alt' />
                      ))}
                    </p>
                    <p>
                      <span className='product-price'>₹{product.price}</span>
                      <span className='discountprice'>₹{product.discountedPrice}</span>
                    </p>
                    <div className='btn-shop-icon'>
                      <button>
                        <span>
                          <img src={lockicon} alt='alt' className='shopping-icon' />
                        </span>
                        <span className='btn-shop-txt'>Add To Cart</span>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
