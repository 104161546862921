import React, { useState } from "react";
import "../styles/ourCustomerSay.css"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import TrendingHeaderDash from "../svg/trendingProductDash.svg";
import InvertedCommaSvg from "../svg/invertedCommaSvg.svg";
import ReactStars from 'react-stars'
import { render } from "react-dom";


const widthOutput = window.screen.width;
const trendingHeader = (1.8 / 100) * widthOutput;
const contentFontSize = (1.1 / 100) * widthOutput;


const trendingHeaderStyle = {
    fontSize: `${trendingHeader}px`,
    fontWeight:800
}

const contentStyles = {
    fontSize: `${contentFontSize}px`,
    lineHeight: 2,
    fontWeight: 100,
    paddingTop: "2rem",
    textTransform: "capitalize",
    textAlign: "center"
}
const NameStyles = {
    fontSize: `${contentFontSize}px`,
    lineHeight: 2,
    // fontWeight:100,
    paddingTop: "2rem",
    textTransform: "capitalize",
    textAlign: "center"
}
const starComponent = {
    padding:"0 1rem"
}


const imageData = [
    {
        invertedComma: InvertedCommaSvg,
        content: "Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag. Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag.",
        name: "mohhit chauhan",
        rating: 3
    },
    {
        invertedComma: InvertedCommaSvg,
        content: "Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag. Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag.",
        name: "mohhit chauhan",
        rating: 2.5
    },
    {
        invertedComma: InvertedCommaSvg,
        content: "Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag. Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag.",
        name: "mohhit chauhan",
        rating: 5
    },
    {
        invertedComma: InvertedCommaSvg,
        content: "Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag. Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, ossa. Revönde sygt yliga med ljudöra. Pohag.",
        name: "mohhit chauhan",
        rating: 1
    }
];



export const OurCustomerSay = () => {
    // const [currentIndex, setCurrentIndex] = useState();
    // function handleChange(index) {
    //     setCurrentIndex(index);
    // }
    const ratingChanged = (newRating) => {
        console.log(newRating);
    };
    return (
        <div className="App_OurCustomerSay">
            <div className="headerDiv_OurCustomerSay">
                <h1 style={trendingHeaderStyle}><img src={TrendingHeaderDash} />&nbsp; our customer say &nbsp; <img src={TrendingHeaderDash} /></h1>
            </div>
            <Carousel
                showArrows={true}
                autoPlay={true}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                transitionTime={500}
                // selectedItem={imageData[currentIndex]}
                // onChange={handleChange}
                className="carousel-container_OurCustomerSay"
                renderArrowPrev={(clickHandler, hasPrev) => {
                    return (
                        <div
                            className={`${hasPrev ? 'absolute' : 'hidden'}`}
                            onClick={clickHandler}
                            style={{
                                top: "35%",
                                left: "10%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: 3,
                                opacity: 30,
                                cursor: "pointer",
                                zIndex: 20,
                                position: "absolute"
                            }}
                        >
                            <svg width="36" height="36" viewBox="0 0 43 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="1" y="1" width="41" height="42" rx="2" stroke="#646464" stroke-width="1.5" />
                                <path d="M25 15L18 22L25 29" stroke="#222222" stroke-width="1.5" />
                            </svg>
                        </div>
                    );
                }}
                renderArrowNext={(clickHandler, hasNext) => {
                    return (
                        <div
                            className={`${hasNext ? 'absolute' : 'hidden'}`}
                            onClick={clickHandler}
                            style={{
                                top: "35%",
                                right: "10%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: 3,
                                opacity: 30,
                                cursor: "pointer",
                                zIndex: 20,
                                position: "absolute"
                            }}
                        >
                            <svg width="36" height="36" viewBox="0 0 43 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="41" height="42" rx="2" transform="matrix(-1 0 0 1 42 1)" stroke="#646464" stroke-width="1.5" />
                                <path d="M18 15L25 22L18 29" stroke="#222222" stroke-width="1.5" />
                            </svg>
                        </div>
                    );
                }}
            >
                {
                    imageData.map((image) => (
                        <div className="ourCustomeMainDiv_OurCustomer" key={image.alt}>
                            <img style={{
                                width: "5%"
                            }} src={image.invertedComma} alt={image.invertedComma} />
                            <h1 style={contentStyles} className="content_OurCustomerSay">{image.content}</h1>
                            <h2 style={NameStyles} >{image.name}</h2>
                            <ReactStars
                                count={5}
                                edit={false}
                                // onChange={ratingChanged}
                                value={image.rating}
                                size={16}
                                isHalf={true}
                                activeColor="#FFB100"
                                color1={'#dddddd'}
                                className="starComponent"
                            />
                        </div>
                    ))
                }
            </Carousel>
        </div>
    );
}