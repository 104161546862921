import React from "react";
import "../styles/termsAnsServices.css";
import Layout from "../component/layout";
import BreadCrum from "../component/BreadCrum";


const widthOutput = window.screen.width;
const headerJHeadingFontSize = (2.2 / 100) * widthOutput;
const titleHeaderFontSize = (1.6 / 100) * widthOutput;

const headerHeadingStyles = {
    fontSize: `${headerJHeadingFontSize}px`
}

export const TremsANdServices = () => {
    return (
        <Layout>
            <BreadCrum currentPageName="Term and Services" />
            <div className="mainDiv_TremsAndServices">
                <div className="innerMainDiv_TremsAndServices">
                    <div className="headerWithCOntentMainDiv_TremsAndServices">
                        <div className="headerHeading_TremsAndServices">
                            <h1 style={headerHeadingStyles}>Term and Services</h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>This website ("Site") and all its contents are owned and operated by M K Agrotech Private Limited . Your access to and use of this Site is subject to the terms and conditions of these ‘Terms of Use’ and all applicable laws. The term ‘Affiliated Entities’ means and includes subsidiaries or affiliated companies or authorised associates or agents.
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_TremsAndServices">
                        <div className="termsOfUseTitleMainDiv_TremsAndServices">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>ACCEPTANCE OF TERMS OF USE</h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>By accessing and using this Site, you accept and agree to these Terms of Use (Terms) and to any additional terms, amendments and /or supplementary rules that may be posted on this Site, without any limitation or qualification. By impliedly or expressly accepting these Terms of Use, You also accept and agree to be bound by
                                M K Agrotech Private Limited Policies (including but not limited to Privacy Policy) as amended from time to time. If you do not agree to these Terms, you may not use this Site.
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_TremsAndServices">
                        <div className="termsOfUseTitleMainDiv_TremsAndServices">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>DATA PROTECTION AND PRIVACY POLICY</h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>M K Agrotech Private Limited respects its concern about data protection and privacy of your personal and sensitive information and takes protection of such personal and sensitive information seriously. Please refer our Privacy Policy for information on how we collect, store, use, disclose and manage your personal information.
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_TremsAndServices">
                        <div className="termsOfUseTitleMainDiv_TremsAndServices">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>CONTENT WE MAKE AVAILABLE ON THE SITE
                            </h1>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`,
                                paddingTop:"2rem"
                            }}>This Site of M K Agrotech Private Limited has been developed to provide general public information which is subject to change without notice. The documents and information displayed in this site are for reference purposes only. We try to ensure that Site availability is uninterrupted and that transmissions will be error-free. However, we cannot, due to the nature of the internet,
                                guarantee that your access will not be suspended or restricted from time to time including to allow for repairs, maintenance or the introduction of new
                                services. We of course try to limit the frequency and duration of any suspension or restriction.
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>The content on the Site is updated on a continual basis. While Company attempts to keep its web information accurate and timely, no representations, warranties or guarantees whatsoever are made as to the accuracy, adequacy, reliability,
                                completeness, suitability or applicability of the information, text, graphics, hyperlinks, and other items contained on this server or any other server. M K Agrotech Private Limited , its directors, officers, employees, its affiliated entities or agents shall not be liable for any loss, damage or expense arising out of use /access of this site or any site linked to it including without limitation of any consequential loss, indirect, incidental or damages of whatsoever nature.
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>Should any viewer of this Site respond with information including feedback data such as questions, comments, suggestions, or the like regarding the content of any material on this Site, such information shall be deemed to be non-confidential and M K Agrotech Private Limited shall deal with the same in the manner it deems fit.
                            </h4>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_TremsAndServices">
                        <div className="termsOfUseTitleMainDiv_TremsAndServices">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>NTELLECTUAL PROPERTY
                            </h1>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`,
                                paddingTop:"2rem"
                            }}>In case the person who placed the order is not available at the delivery address, any person available at the delivery address(residential or otherwise) can receive the Products. This will be considered as order delivered.I M K Agrotech Private Limited (or Affiliated Entities who have licensed its brands to the Company or content made available to this Site) own or licence from third parties all Site content. All Site content including copyright and other intellectual property rights in
                                all materials presented on the Site (including but not limited to text, audio, video, sound, software, graphical images and other materials), trademarks and logos on this Site remains the sole property of M K Agrotech Private Limited  / Affiliated Entities and is protected under all relevant Copyright, Trademark and other applicable laws. Nothing on the Site should be construed as granting any license or rights to use or distribute or reproduce, modify or disseminate any Site content, without our prior express written agreement or of the other applicable content owner.
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>Authorization to access and use is subject to the following restrictions:
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>Companymay review, edit, or delete materials you or others send to this Site, but are under no obligation to do so and assume no responsibility or liability arising from the material. Company may cancel or terminate your access or use of the Site of any registration/s or account/s on this Site at any time, without notice or liability, for any reason, including if technical problems,
                                irregularities, misuse or breach occurs.
                            </h4>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_TremsAndServices">
                        <div className="termsOfUseTitleMainDiv_TremsAndServices">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>MATERIAL /CONTENT YOU SHARE WITH US
                            </h1>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`,
                                paddingTop:"2rem"
                            }}>Any material /content shared and sent by you via this Site or otherwise to M K Agrotech Private Limited  are on a non-confidential basis, and M K Agrotech Private Limited is under no obligation to refrain from reproducing, publishing or otherwise using them in any way or for any purpose. M K Agrotech Private Limitedshall be free to use the content of any such material / communication, including any ideas, inventions, concepts, techniques or know-how disclosed therein, for any purpose including developing manufacturing and/or marketing goods and services.
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>You agree to not assert any ownership right of any kind in the Material /Content (including, but not limited to copyright, trademark, unfair competition, moral rights, or implied contract) and to waive the right to receive any financial or other consideration in connection with such Material / content including, but not limited to, your acknowledgment.
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>You shall be responsible for the Material /Content and information contained in any Material /Content sent by you to this Site or otherwise to M K Agrotech Private Limited, including its truthfulness and accuracy.
                            </h4>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_TremsAndServices">
                        <div className="termsOfUseTitleMainDiv_TremsAndServices">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>DISCLAIMER
                            </h1>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`,
                                paddingTop:"2rem"
                            }}>Any material/ contents downloaded or otherwise obtained through the use of the Site is done at your own discretion and risk. The Site is provided to you “AS IS” without any express warranties or representations of any kind. M K Agrotech Private Limited, its Affiliated Entities and their directors, officers, employees, agents, providers, representatives and licensors disclaim all statutory and implied representations, warranties with respect to this Site and shall not be responsible or liable for any damages, claims, delays, inaccuracies, errors or omissions arising out of your use of the Site or with respect to the material contained on the Site due
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>(a) Statement, acts, errors or omissions in heSite
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>(b) availability of Site content on timely basis or uninterrupted and secured access,
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>(c) content infringing any third party’s rights information and materials
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>(d) Any viruses or other contamination of your system , nor for any damages,
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>(e) linking to any other site or its nature or contents
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>(f) that defects or errors will be corrected or
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>(g) any other matter regarding this Siteand your use of it.
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>Nothing on this Site constitutes an invitation or offer to invest or deal in the securities of M K Agrotech Private Limited. In particular, actual results and developments may be materially different from any forecast, opinion or expectation expressed on this Site as also from the historical performance on account of factors such as changes in government regulations, tax regimes, economic developments within India and the countries within which the Company conducts its business, exchange rate and interest rate movements, impact of competing products and their pricing, product demand and supply constraints. The past performance of the price of securities must not be relied on as a guide to their future performance.
                            </h4>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_TremsAndServices">
                        <div className="termsOfUseTitleMainDiv_TremsAndServices">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>INDEMNIFICATION
                            </h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>By accessing or using this Site, you agree to defend, indemnify and hold harmless M K Agrotech Private Limited and its Affiliated Entities and their respective directors, officers, employees, agents, providers, representatives and licensors from against all claims, losses, costs and expenses including without limitation lawyer’s fees and cost arising out of any of the following.
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_TremsAndServices">
                        <div className="termsOfUseTitleMainDiv_TremsAndServices">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>EXTERNAL LINKS
                            </h1>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`,
                                paddingTop:"2rem"
                            }}>This Site may provide links to other websites operated and owned by other entities. Some of the links on this Site lead to resources/ servers owned, operated and maintained by other entities, individuals or organizations over which M K Agrotech Private Limited has no control.
                            </h4>
                            <h4 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>M K AgrotechPrivate Limited makes no representations or warranties regarding the accuracy or any other aspect of the information located on such servers and does not bear any responsibility whatsoever for the content, accuracy or security of any websites that are linked by way of hyperlink or otherwise to this Site. Visiting any such linked site/s, any downloading of material or any form of data usage, you do it at your own risk including risk of infringing intellectual property rights or inviting viruses into your system. References to any external links should not be construed as an endorsement of the links or their content or its products and services.
                            </h4>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_TremsAndServices">
                        <div className="termsOfUseTitleMainDiv_TremsAndServices">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>SEVERABILITY
                            </h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>Each term and condition listed herein is severable. If any term or condition here of is illegal, invalid or inapplicable such illegality, invalidity or inapplicability shall not affect the validity of the remaining terms and conditions.
                            </h3>
                        </div>
                    </div>
                    <div className="termsOfUseMainDiv_TremsAndServices">
                        <div className="termsOfUseTitleMainDiv_TremsAndServices">
                            <h1 style={{
                                fontSize: `${titleHeaderFontSize}px`
                            }}>CHANGES
                            </h1>
                            <h3 style={{
                                fontSize: `${headerJHeadingFontSize * 0.45}px`
                            }}>M K Agrotech Private Limited reserves the right, as and when it considers appropriate and at its sole discretion, to alter, change, make corrections, modify, add or remove any portion of this Terms of Use at any time and without notice.
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}