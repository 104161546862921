import React from "react";
import "../styles/ourSpeaciality.css";
import TrendingHeaderDash from "../svg/trendingProductDash.svg";
import OurSecialityBanner from "../images/ourSpecialityBanner.png";

const widthOutput = window.screen.width;
const trendingHeader = (1.8 / 100) * widthOutput;

const trendingHeaderStyle = {
    fontSize: `${trendingHeader}px`,
    fontWeight:800
}

export const OurSpeciality = () => {
    return (<>
        <div className="mainDIv_OurSpeciality">
            <div className="InnerMainDiv_OurSpeciality">
                <div className="headerDiv_OurSpeciality">
                    <h1 style={trendingHeaderStyle}><img src={TrendingHeaderDash} />&nbsp; our speciality &nbsp; <img src={TrendingHeaderDash} /></h1>
                </div>
                <div className="banner_OurSpeciality">
                    <img src={OurSecialityBanner} />
                </div>
            </div>
        </div>
    </>)
}