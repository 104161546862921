import React, { useState } from "react";
import NoteContext from "./noteContext";
import { useEffect } from "react";
import { useRef } from "react";

const Notestate = ({ children }) => {
    const [isHome, setIsHome] = useState(false)
    const [shopByCategoriesState, setShopByCategoriesState] = useState()
    const [activeAccount, setActiveAccount] = useState(false)
    const [activeWishList, setActiveWishlist] = useState(false)
    const [activeCart, setActiveCart] = useState(false)
    const [totalItemWishlist,setTotalItemWishlist] = useState(0)


    const shopByCategories = useRef(null);
    useEffect(() => {
        if (isHome) {
            setShopByCategoriesState(0)
        } else {
            setShopByCategoriesState(shopByCategories)
        }
    }, [])

    const values = {
        setIsHome,
        isHome,
        shopByCategoriesState,
        setActiveAccount,
        activeAccount,
        setActiveWishlist,
        activeWishList,
        setActiveCart,
        activeCart,
        setTotalItemWishlist,
        totalItemWishlist
    }

    return (
        <NoteContext.Provider value={values}>
            {children}
        </NoteContext.Provider>
    )
}

export default Notestate;