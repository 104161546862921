import React, { useRef, useState } from "react";
import "../styles/accountSection.css";
import Layout from "../component/layout";
import {
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBRow,
} from "mdb-react-ui-kit";
import AccountSectionIcon from "../svg/accountHolderIcon.svg";
import MyOrderIcon from "../svg/accountIcon.svg";
import SugnOutIcon from "../svg/signOutIcon.svg";
import EditPencilIcon from "../svg/editPencil.svg";
import DropDownIcon from "../svg/dropdown.svg";
import { BiChevronDown } from "react-icons/bi";
import Img1 from "../images/img1.png";
import BackIcon from "../svg/backArrow.svg";
import CrossIcon from "../svg/cross.svg";
import { Badge, Box, TextField } from "@material-ui/core";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import BreadCrum from "../component/BreadCrum";
import { useContext } from "react";
import noteContext from "../context/noteContext";
import { useEffect } from "react";

const widthOutput = window.screen.width;
const accountHolderTitleFontSize = (1.05 / 100) * widthOutput;
const accountSectionTitleFontSize = (0.9 / 100) * widthOutput;
const accountSettingHeadferTitleFOntSize = (1.4 / 100) * widthOutput;
const accountSettingEditBtnFontSIze = (1.1 / 100) * widthOutput;
const dateFilterBtnFontSize = (1 / 100) * widthOutput;
const BuyNowBtnSize = (2.5 / 100) * widthOutput;
const productDetailsFontSize = (0.9 / 100) * widthOutput;
const dropDownTitleFOntSize = (1 / 100) * widthOutput;
const orderDateDetailsFontSize = (0.9 / 100) * widthOutput;
const tractOrderBtnFOntSize = (0.9 / 100) * widthOutput;
const paymentMethodDetailsFontSize = (0.9 / 100) * widthOutput;
const orderIdFontSIze = (1.3 / 100) * widthOutput;
const tractStatusTitleFontSize = (1 / 100) * widthOutput;
const expectedDatedFontSize = (1 / 100) * widthOutput;
const shippingAddressTrackOrderFontSize = (1.2 / 100) * widthOutput;
const orderDetailsTrackOrderFontSize = (1.3 / 100) * widthOutput;
const orderListPopupTitleFontSize = (1.3 / 100) * widthOutput;
const orderDetailsHeaderBtnFontSize = (0.8 / 100) * widthOutput;

const accountHolderTitleStyle = {
    fontSize: `${accountHolderTitleFontSize}px`
}
const accountHolderSectionTitleStyles = {
    fontSize: `${accountSectionTitleFontSize}px`
}
const accountSettingHeadetTitleStyles = {
    fontSize: `${accountSettingHeadferTitleFOntSize}px`

}
const accountSettingEditBtnStyls = {
    fontSize: `${accountSettingEditBtnFontSIze}px`,
    fontWeight: 100
}
const dropDownTitleStyles = {
    fontSize: `${dropDownTitleFOntSize}px`
}
const orderDateDetailsStyles = {
    fontSize: `${orderDateDetailsFontSize}px`
}
const orderIdMainDIvSTyles = {
    fontSize: `${orderIdFontSIze}px`,
}

const orderFilter = [
    {
        name: "Recent Order"
    },
    {
        name: "This Year"
    },
    {
        name: "This Week"
    },
    {
        name: "This Month"
    },
]

const orderProductList = [
    {
        indicator: "green",
        orderDate: "Order on 2nd apr 2023",
        status: "(Arrving today)",
        paymentMode: "cash on delivery",
        subtotal: 1440,
        productImg: [
            {
                img: Img1,
            },
            {
                img: Img1,
            },
            {
                img: Img1,
            },
            {
                img: Img1,
            },
            {
                img: Img1,
            },
            {
                img: Img1,
            },
        ]
    },
    {
        indicator: "red",
        orderDate: "Return on 2nd apr 2023",
        status: null,
        paymentMode: "cash on delivery",
        subtotal: 1440,
        productImg: [
            {
                img: Img1,
            },
            {
                img: Img1,
            },
            {
                img: Img1,
            },
            {
                img: Img1,
            },
            {
                img: Img1,
            },
            {
                img: Img1,
            },
        ]
    },
    {
        indicator: "green",
        orderDate: "Delivered on 2nd apr 2023",
        status: null,
        paymentMode: "net banking",
        subtotal: 1440,
        productImg: [
            {
                img: Img1,
            },
            {
                img: Img1,
            },
            {
                img: Img1,
            },
            {
                img: Img1,
            },
            {
                img: Img1,
            },
            {
                img: Img1,
            },
        ]
    },
]

const orderProductListPopup = [
    {
        img: Img1,
        title: "Fresh sunflower oil 990ml",
        size: "990ml",
        price: 210,
        actualPrice: 350,
        numberOfQuantity: 5,
    },
    {
        img: Img1,
        title: "Fresh sunflower oil 990ml",
        size: "990ml",
        price: 210,
        actualPrice: 350,
        numberOfQuantity: 2,
    },
    {
        img: Img1,
        title: "Fresh sunflower oil 990ml",
        size: "990ml",
        price: 210,
        actualPrice: 350,
        numberOfQuantity: 0,
    },
    {
        img: Img1,
        title: "Fresh sunflower oil 990ml",
        size: "990ml",
        price: 210,
        actualPrice: 350,
        numberOfQuantity: 1,
    },
    {
        img: Img1,
        title: "Fresh sunflower oil 990ml",
        size: "990ml",
        price: 210,
        actualPrice: 350,
        numberOfQuantity: 19,
    },
]


export const AccountSection = () => {
    const [accountSettingActive, setAccountSettingActive] = useState(true);
    const [myOrderActive, setMyOrderActive] = useState(false);
    const [signOutActive, setSignOutActive] = useState(false);
    const [dateFilterDropDown, setDateFilterDropDown] = useState();
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [openCountry, setOpenCountry] = useState(false);
    const [activeTrackOrder, setActiveTrackOrder] = useState(false);
    const [orderProdutPopup, setorderProductPopup] = useState();
    const [viewOrderDetails, setViewOrderDetails] = useState();
    const [activeViewOrderDetails, setActiveViewOrderDetails] = useState(false);
    const { setActiveAccount } = useContext(noteContext)
   


    const arrowStyled = {
        // transform: rotate(0)
        transform: `${openCountry ? "rotate(180deg)" : "rotate(0)"}`
    }

    console.log("checking true false", activeViewOrderDetails)

    useEffect(() => {
        setActiveAccount(true)
    }, [])


    const HandleAccountSettingActive = () => {
        setAccountSettingActive(true)
        setMyOrderActive(false)
        setSignOutActive(false)
    }
    const HandleMyOrderActive = () => {
        setAccountSettingActive(false)
        setMyOrderActive(true)
        setSignOutActive(false)
    }
    const HandleSignOutActive = () => {
        setAccountSettingActive(false)
        setMyOrderActive(false)
        setSignOutActive(true)
    }
    const handleOpenViewDetials = (productDetails) => {
        setViewOrderDetails(productDetails)
        setActiveViewOrderDetails(true)
    }

    const handleActiveTrackOrder = () => {
        setActiveViewOrderDetails(!activeViewOrderDetails)
        setActiveTrackOrder(!activeTrackOrder)
    }
    function auto_grow(element) {
        element.style.height = "5px";
        element.style.height = (element.scrollHeight) + "px";
    }

    return (
        <Layout>
            <BreadCrum
                previousPage={
                    accountSettingActive ? "Account" : (myOrderActive ? "Account" : (signOutActive ? "Account" : null))
                }
                currentPageName={
                    accountSettingActive ? "Account Setting" : (myOrderActive ? "My Order" : (signOutActive ? "Sign Out" : "Account"))
                }
                currentPage1={
                    activeTrackOrder ? "Track Order" : (activeViewOrderDetails ? "View Order Details" : null)
                }
            />
            <div className="mainDiv_AccountSection">
                <div className="innerMainDiv_AccountSection">
                    <div className="leftMenuMainDIv_AccountSection">
                        <div className="accountHolderTitle_AccountSection">
                            <h1 style={accountHolderTitleStyle}>Mohit Chuahan</h1>
                        </div>
                        <div onClick={() => HandleAccountSettingActive()} className="accountHolderSection_AccountSection">
                            <div style={{
                                backgroundColor: `${accountSettingActive ? "#06060608" : ""}`,
                                borderRight: `${accountSettingActive ? "2px solid #000" : ""}`,
                                marginRight: "-2px"
                            }} className="accountHolderInnerSection_AccountSection">
                                <div className="innerAccountHolderInnerSection_AccountSection">
                                    <img src={AccountSectionIcon} />
                                    <h2 style={accountHolderSectionTitleStyles}>&nbsp;&nbsp;&nbsp;Account setting</h2>
                                </div>
                            </div>
                        </div>
                        <div style={{
                            backgroundColor: `${myOrderActive ? "#06060608" : ""}`,
                            borderRight: `${myOrderActive ? "2px solid #000" : ""}`,
                            marginRight: "-2px"
                        }} onClick={() => HandleMyOrderActive()} className="accountHolderSection_AccountSection">
                            <div className="accountHolderInnerSection_AccountSection">
                                <div className="innerAccountHolderInnerSection_AccountSection">
                                    <img src={MyOrderIcon} />
                                    <h2 style={accountHolderSectionTitleStyles}>&nbsp;&nbsp;&nbsp;my orders</h2>
                                </div>
                            </div>
                        </div>
                        <div style={{
                            backgroundColor: `${signOutActive ? "#06060608" : ""}`,
                            borderRight: `${signOutActive ? "2px solid #000" : ""}`,
                            marginRight: "-2px"
                        }} onClick={() => HandleSignOutActive()} className="accountHolderSection_AccountSection">
                            <div className="accountHolderInnerSection_AccountSection">
                                <div className="innerAccountHolderInnerSection_AccountSection">
                                    <img src={SugnOutIcon} />
                                    <h2 style={accountHolderSectionTitleStyles}>&nbsp;&nbsp;&nbsp;sign out</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rightSectionMenuMainDIv_AccountSection">
                        {
                            accountSettingActive ?
                                <div className="accountSettingMainDivAccountSetting_AccountSection">
                                    <div className="accountSettingHeaderMainDIv_AccountSection">
                                        <div className="headerTitleAccountSetting_AccountSection">
                                            <h1 style={accountSettingHeadetTitleStyles}>account setting</h1>
                                        </div>
                                        <div className="headerButtonAccountSetting_AccountSection">
                                            <h2 style={accountSettingEditBtnStyls}>edit &nbsp; <img src={EditPencilIcon} /></h2>
                                            <button style={{
                                                fontSize: `${accountSettingEditBtnFontSIze}px`
                                            }}>
                                                Delete account
                                            </button>
                                        </div>
                                    </div>
                                    <div className="accountSettingCOntentMainDiv_AccountSection">
                                        <div
                                            style={{
                                                borderBottom: "1px solid #C7C7C7"
                                            }} className="personalDetailsAccountSetting_AccountSection">
                                            <div className="headerPersonalDetailsAccountSetting_AccountSection">
                                                <h1 style={{
                                                    fontSize: `${shippingAddressTrackOrderFontSize}px`
                                                }}>Personal details</h1>
                                            </div>
                                            <div className="detailsPersonalDetailsAccountSetting_AccountSection">
                                                <div className="nameDeatilsPersonalDetailsAccountSetting_AccountSection">
                                                    <h2 style={{
                                                        fontSize: `${shippingAddressTrackOrderFontSize * 0.9}px`
                                                    }}>first name</h2>
                                                    <input
                                                        placeholder="Mohit"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="nameDeatilsPersonalDetailsAccountSetting_AccountSection">
                                                    <h2 style={{
                                                        fontSize: `${shippingAddressTrackOrderFontSize * 0.9}px`
                                                    }}>Last name</h2>
                                                    <input
                                                        placeholder="chauhan"
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                            <div className="detailsPersonalDetailsAccountSetting_AccountSection">
                                                <div className="nameDeatilsPersonalDetailsAccountSetting_AccountSection">
                                                    <h2 style={{
                                                        fontSize: `${shippingAddressTrackOrderFontSize * 0.9}px`,

                                                    }}>Email address</h2>
                                                    <input
                                                        style={{
                                                            textTransform: "lowercase",
                                                        }}
                                                        placeholder="mohitchauhan43@gmail.com"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="nameDeatilsPersonalDetailsAccountSetting_AccountSection">
                                                    <h2 style={{
                                                        fontSize: `${shippingAddressTrackOrderFontSize * 0.9}px`
                                                    }}>phone no.</h2>
                                                    <input
                                                        placeholder="9078342836"
                                                        type="number"
                                                    />
                                                </div>
                                            </div>
                                            <div className="detailsPersonalDetailsAccountSetting_AccountSection">
                                                <div className="nameDeatilsPersonalDetailsAccountSetting_AccountSection">
                                                    <h2 style={{
                                                        fontSize: `${shippingAddressTrackOrderFontSize * 0.9}px`,

                                                    }}>password</h2>
                                                    <input
                                                        style={{
                                                            textTransform: "lowercase",
                                                        }}
                                                        placeholder="**********"
                                                        type="password"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="personalDetailsAccountSetting_AccountSection">
                                            <div className="headerPersonalDetailsAccountSetting_AccountSection">
                                                <h1 style={{
                                                    fontSize: `${shippingAddressTrackOrderFontSize}px`
                                                }}>address details</h1>
                                            </div>
                                            <div className="detailsPersonalDetailsAccountSetting_AccountSection">
                                                <div className="nameDeatilsLowerPersonalDetailsAccountSetting_AccountSection">
                                                    <h2 style={{
                                                        fontSize: `${shippingAddressTrackOrderFontSize * 0.9}px`
                                                    }}>country</h2>
                                                    <Box style={{
                                                        border: "1px solid transparent",
                                                        borderRadius: "4px",
                                                        background: "#F9FAFC"
                                                    }} sx={{ minWidth: 120 }}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">India</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                // value={age}
                                                                label="Age"
                                                            // onChange={handleChange}
                                                            >
                                                                <MenuItem value={10}>Ten</MenuItem>
                                                                <MenuItem value={20}>Twenty</MenuItem>
                                                                <MenuItem value={30}>Thirty</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </div>
                                                <div className="nameDeatilsLowerPersonalDetailsAccountSetting_AccountSection">
                                                    <h2 style={{
                                                        fontSize: `${shippingAddressTrackOrderFontSize * 0.9}px`
                                                    }}>state</h2>
                                                    <Box style={{
                                                        border: "1px solid transparent",
                                                        borderRadius: "4px",
                                                        background: "#F9FAFC"
                                                    }} sx={{ minWidth: 120 }}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">Maharashtra</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                // value={age}
                                                                label="Age"
                                                            // onChange={handleChange}
                                                            >
                                                                <MenuItem value={10}>Ten</MenuItem>
                                                                <MenuItem value={20}>Twenty</MenuItem>
                                                                <MenuItem value={30}>Thirty</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </div>
                                            </div>
                                            <div className="detailsPersonalDetailsAccountSetting_AccountSection">
                                                <div className="nameDeatilsLowerPersonalDetailsAccountSetting_AccountSection">
                                                    <h2 style={{
                                                        fontSize: `${shippingAddressTrackOrderFontSize * 0.9}px`,

                                                    }}>city</h2>
                                                    <Box style={{
                                                        border: "1px solid transparent",
                                                        borderRadius: "4px",
                                                        background: "#F9FAFC"
                                                    }}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">Select Here</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                // value={age}
                                                                label="Age"
                                                                variant="outlined"
                                                            // onChange={handleChange}
                                                            >
                                                                <MenuItem value={10}>Ten</MenuItem>
                                                                <MenuItem value={20}>Twenty</MenuItem>
                                                                <MenuItem value={30}>Thirty</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </div>
                                                <div className="nameDeatilsLowerPersonalDetailsAccountSetting_AccountSection">
                                                    <h2 style={{
                                                        fontSize: `${shippingAddressTrackOrderFontSize * 0.9}px`
                                                    }}>pincode</h2>
                                                    <div style={{
                                                        width: "100%",
                                                        padding: "0"
                                                    }}>
                                                        <Box style={{
                                                            border: "1px solid transparent",
                                                            borderRadius: "4px"
                                                        }}>
                                                            <TextField
                                                                style={{
                                                                    padding: "0"
                                                                }}
                                                                id="standard-helperText"
                                                                // label="First Name"
                                                                placeholder="Enter Here"
                                                                // helperText="Some important text"
                                                                variant="outlined"
                                                                type="number"
                                                            // fullWidth={true}
                                                            // maxLength="90"

                                                            />
                                                        </Box>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="detailsPersonalDetailsAccountSetting_AccountSection">
                                                <div style={{
                                                    width: "100%"
                                                }} className="nameDeatilsPersonalDetailsAccountSetting_AccountSection">
                                                    <h2 style={{
                                                        fontSize: `${shippingAddressTrackOrderFontSize * 0.9}px`,

                                                    }}>address</h2>
                                                    <textarea
                                                        style={{
                                                            width: "96%"
                                                        }}
                                                        rows="2"
                                                        placeholder="Lörem ipsum neköde egobel. Pomosk dinade. Portfoliometoden poment mogesade, "
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                        {
                            myOrderActive ?
                                <div className="myOrderMainDiv_AccountSection">
                                    <div className="myOrderInnerMainDiv_AccountSection">
                                        {
                                            activeTrackOrder ?
                                                <div className="headerTitleMyOrder_AccountSection">
                                                    <h1 onClick={() => handleActiveTrackOrder()} style={accountSettingHeadetTitleStyles}>

                                                        <img style={{
                                                            cursor: "pointer",
                                                        }} src={BackIcon} title="Back To My Order" /> &nbsp;track Order
                                                    </h1>
                                                </div>
                                                : (
                                                    activeViewOrderDetails ?
                                                        <div className="headerTitleMyOrder_AccountSection">
                                                            <h1 onClick={() => setActiveViewOrderDetails(false)} style={accountSettingHeadetTitleStyles}>
                                                                <img style={{
                                                                    cursor: "pointer",
                                                                }} src={BackIcon} title="Back To My Order" /> &nbsp;order details
                                                            </h1>
                                                            <div className="orderDetailsHeaderBtn_AccountSection">
                                                                <button style={{
                                                                    fontSize: `${orderDetailsHeaderBtnFontSize}px`,
                                                                    border: "none",
                                                                }}>
                                                                    cancel order
                                                                </button>
                                                                <button onClick={() => handleActiveTrackOrder()} style={{
                                                                    fontSize: `${orderDetailsHeaderBtnFontSize}px`
                                                                }}>
                                                                    track order
                                                                </button>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="headerTitleMyOrder_AccountSection">
                                                            <h1 style={accountSettingHeadetTitleStyles}>my order <span style={{
                                                                fontSize: `${accountSettingHeadferTitleFOntSize * 0.8}px`,
                                                                fontWeight: 100,
                                                                color: "#787878"
                                                            }}>&nbsp;(14)</span></h1>
                                                            <div className="dateFilterDropDownMainDivMyOrder_AccountSection">
                                                                <div
                                                                    onClick={() => setOpen(!open)}
                                                                    className="selectMainDivMyOrder_AccountSection"
                                                                    style={{
                                                                        width: `${BuyNowBtnSize * 4}px`,
                                                                        height: `${BuyNowBtnSize}px`,
                                                                        fontSize: `${dropDownTitleFOntSize}px`,
                                                                        fontWeight: 600
                                                                    }}
                                                                >
                                                                    {selected
                                                                        ? selected?.length > 15
                                                                            ? selected?.substring(0, 15) + "..."
                                                                            : selected
                                                                        : <h2 style={dropDownTitleStyles}>Recent Orders</h2>}
                                                                    <BiChevronDown size={20} style={arrowStyled}
                                                                    />
                                                                    {
                                                                        open ? <ul
                                                                            className="selectUlMyOrder_AccountSection"
                                                                            style={{
                                                                                // width: `${BuyNowBtnSize * 4}px`,
                                                                                top: `${BuyNowBtnSize * 1.2}px`,
                                                                                fontSize: `${productDetailsFontSize}px`
                                                                            }}
                                                                        >
                                                                            {orderFilter?.map((item) => (
                                                                                <li
                                                                                    style={{
                                                                                        fontWeight: 100
                                                                                    }}
                                                                                    key={item?.name}
                                                                                    onClick={() => {
                                                                                        if (item?.name?.toLowerCase() !== selected.toLowerCase()) {
                                                                                            setSelected(item?.name);
                                                                                            setOpen(false);
                                                                                            setInputValue("");
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {item?.name}
                                                                                </li>
                                                                            ))}
                                                                        </ul> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                )


                                        }
                                        {/* <div className="headerTitleMyOrder_AccountSection">
                                            <h1 style={accountSettingHeadetTitleStyles}>my order <span style={{
                                                fontSize: `${accountSettingHeadferTitleFOntSize * 0.8}px`,
                                                fontWeight: 100,
                                                color: "#787878"
                                            }}>&nbsp;(14)</span></h1>
                                            <div className="dateFilterDropDownMainDivMyOrder_AccountSection">
                                                <div
                                                    onClick={() => setOpen(!open)}
                                                    className="selectMainDivMyOrder_AccountSection"
                                                    style={{
                                                        width: `${BuyNowBtnSize * 4}px`,
                                                        height: `${BuyNowBtnSize}px`,
                                                        fontSize: `${dropDownTitleFOntSize}px`,
                                                        fontWeight: 600
                                                    }}
                                                >
                                                    {selected
                                                        ? selected?.length > 15
                                                            ? selected?.substring(0, 15) + "..."
                                                            : selected
                                                        : <h2 style={dropDownTitleStyles}>Recent Orders</h2>}
                                                    <BiChevronDown size={20} style={arrowStyled}
                                                    />
                                                    {
                                                        open ? <ul
                                                            className="selectUlMyOrder_AccountSection"
                                                            style={{
                                                                // width: `${BuyNowBtnSize * 4}px`,
                                                                top: `${BuyNowBtnSize * 1.2}px`,
                                                                fontSize: `${productDetailsFontSize}px`
                                                            }}
                                                        >
                                                            {orderFilter?.map((item) => (
                                                                <li
                                                                    style={{
                                                                        fontWeight: 100
                                                                    }}
                                                                    key={item?.name}
                                                                    onClick={() => {
                                                                        if (item?.name?.toLowerCase() !== selected.toLowerCase()) {
                                                                            setSelected(item?.name);
                                                                            setOpen(false);
                                                                            setInputValue("");
                                                                        }
                                                                    }}
                                                                >
                                                                    {item?.name}
                                                                </li>
                                                            ))}
                                                        </ul> : null
                                                    }
                                                </div>
                                            </div>
                                        </div> */}
                                        {
                                            activeTrackOrder ?
                                                <div>
                                                    <div className="trackOrderMainDIv_MyOrderAccountSection">
                                                        <div className="OrderIdMainDivMyOrder_AccountSection">
                                                            <h1 style={orderIdMainDIvSTyles}>order id: <span>524725</span></h1>
                                                        </div>
                                                        <div className="trackStatusMainDivMyOrder_AccountSection">
                                                            <div
                                                                className="trackStatusInnerMainDivMyOrder_AccountSection"
                                                            >
                                                                <section className="vh-100 gradient-custom-2">
                                                                    <MDBContainer className="py-5 h-100">
                                                                        <MDBRow className="justify-content-center align-items-center h-100">
                                                                            <MDBCol md="10" lg="8" xl="6">
                                                                                <MDBCard
                                                                                    className="card-stepper"
                                                                                    style={{ borderRadius: "16px" }}
                                                                                >

                                                                                    <MDBCardBody className="p-4">

                                                                                        <ul
                                                                                            id="progressbar-1"
                                                                                            className="mx-0 mt-0 mb-5 px-0 pt-0 pb-4"
                                                                                        >
                                                                                            <li className="step0 active" id="step1">
                                                                                                <span style={{ marginLeft: "22px", marginTop: "12px", fontSize: `${tractStatusTitleFontSize}px` }}>
                                                                                                    PLACED
                                                                                                </span>
                                                                                            </li>
                                                                                            <li className="step0 active text-center" id="step2">
                                                                                                <span style={{ fontSize: `${tractStatusTitleFontSize}px` }}>shipping</span>
                                                                                            </li>
                                                                                            <li className="step0   text-center" id="step3">
                                                                                                <span style={{ fontSize: `${tractStatusTitleFontSize}px` }}>out for delivery</span>
                                                                                            </li>
                                                                                            <li className="step0  text-muted text-end" id="step4">
                                                                                                <span style={{ marginRight: "22px", fontSize: `${tractStatusTitleFontSize}px` }}>Delivered</span>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </MDBCardBody>
                                                                                </MDBCard>
                                                                            </MDBCol>
                                                                        </MDBRow>
                                                                    </MDBContainer>
                                                                </section>
                                                            </div>
                                                            <div className="expectedDateDivMyOrder_AccountSection">
                                                                <h2 style={{
                                                                    fontSize: `${expectedDatedFontSize}px`
                                                                }}>(your order is expected to be delivered on 20th aug 2023) </h2>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="lowerDetailsMainDIvTrackOrderMyOrder_AccountSection">
                                                        <div className="shippingDetailsMainDivTrackOrder_AccountSection">
                                                            <h1 style={{
                                                                fontSize: `${shippingAddressTrackOrderFontSize}px`
                                                            }}>shipping address</h1>
                                                            <h2 style={{
                                                                fontSize: `${shippingAddressTrackOrderFontSize * 0.8}px`
                                                            }}>104, ascon acres,<br></br>
                                                                Mira road east, gaandhi nagar, station road, nursing home lane, opposite supermarket
                                                                THANE, MAHARASHTRA 401107</h2>
                                                        </div>
                                                        <div className="orderProductDetailsMainDivTrackOrder_AccountSection">
                                                            <h1 style={{
                                                                fontSize: `${shippingAddressTrackOrderFontSize}px`
                                                            }}>order products</h1>
                                                            <div className="orderProductDetailsImgMainDivTrackOrder_AccountSection">
                                                                <div className="imagesOrderProductTrackOrder_AccountSection">
                                                                    <img className="img1_orderProductDetailsImgTrackOrder_AccountSection" src={Img1} />
                                                                    <img className="img2_orderProductDetailsImgTrackOrder_AccountSection" src={Img1} />
                                                                    <img className="img3_orderProductDetailsImgTrackOrder_AccountSection" src={Img1} />
                                                                </div>
                                                                <div className="moreProductListOrderProductTrackOrder_AccountSection">
                                                                    <h4 onClick={() => setorderProductPopup(true)} style={{
                                                                        fontSize: `${shippingAddressTrackOrderFontSize * 0.8}px`
                                                                    }}> + 4 more</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="buttonDetailsMainDivTrackOrder_AccountSection">
                                                            <button onClick={() => handleActiveTrackOrder()} style={{
                                                                fontSize: `${shippingAddressTrackOrderFontSize * 0.8}px`
                                                            }}>
                                                                view order details
                                                            </button>
                                                            <h3 style={{
                                                                fontSize: `${shippingAddressTrackOrderFontSize * 0.8}px`
                                                            }}>request cancellation</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                (activeViewOrderDetails ?
                                                    <div className="viewOrderDetailsMainDiv_AccountSection">
                                                        <div className="viewOrderDetailsOrderIdMainDiv_AccountSection">
                                                            <h2 style={{
                                                                fontSize: `${tractStatusTitleFontSize}px`
                                                            }}>Order id: 542332 &nbsp; | &nbsp; ordered on 2nd nov 2023</h2>
                                                            <h3 style={{
                                                                fontSize: `${tractStatusTitleFontSize}px`
                                                            }}>payment method: cash on delivery</h3>
                                                        </div>
                                                        <div className="contentDetailsViewOrderMainDIv_AccountSection">
                                                            <div className="contentLeftDetailsViewOrderMainDiv_AccountSection">
                                                                <h1 style={{
                                                                    fontSize: `${tractStatusTitleFontSize * 1.1}px`
                                                                }}>shipping address</h1>
                                                                <h3 style={{
                                                                    fontSize: `${tractStatusTitleFontSize * 0.95}px`
                                                                }}>104, ascon acres,<br></br>
                                                                    Mira road east, gaandhi nagar, station road, nursing home lane, opposite supermarket
                                                                    THANE, MAHARASHTRA 401107</h3>
                                                            </div>
                                                            <div className="contentRightDetailsViewOrderMainDiv_AccountSection">
                                                                <div className="itemsDetailsViewOrder_AccountSection">
                                                                    <h1 style={{
                                                                        fontSize: `${tractStatusTitleFontSize * 1.1}px`
                                                                    }}>Items</h1>
                                                                    <h2 style={{
                                                                        fontSize: `${tractStatusTitleFontSize * 1.1}px`
                                                                    }}>₹ 1,440</h2>
                                                                </div>
                                                                <div className="itemsDetailsViewOrder_AccountSection">
                                                                    <h1 style={{
                                                                        fontSize: `${tractStatusTitleFontSize * 1.1}px`
                                                                    }}>discount</h1>
                                                                    <h2 style={{
                                                                        fontSize: `${tractStatusTitleFontSize * 1.1}px`
                                                                    }}>- ₹ 950</h2>
                                                                </div>
                                                                <div className="deliveryDetailsViewOrder_AccountSection">
                                                                    <h1 style={{
                                                                        fontSize: `${tractStatusTitleFontSize * 1.1}px`
                                                                    }}>delivery charges</h1>
                                                                    <h2 style={{
                                                                        fontSize: `${tractStatusTitleFontSize * 1.1}px`
                                                                    }}>free</h2>
                                                                </div>
                                                                <div className="subTotalDetailsViewOrder_AccountSection">
                                                                    <h1 style={{
                                                                        fontSize: `${tractStatusTitleFontSize * 1.1}px`
                                                                    }}>sub total</h1>
                                                                    <h2 style={{
                                                                        fontSize: `${tractStatusTitleFontSize * 1.1}px`
                                                                    }}>₹ 1,241</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="allItemListViewOrderMainDiv_AccountSection">
                                                            <div className="headerAllItemListViewOrder_AccountSection">
                                                                <h1 style={{
                                                                    fontSize: `${tractStatusTitleFontSize * 1.2}px`
                                                                }}>all items <span style={{
                                                                    fontSize: `${tractStatusTitleFontSize}px`
                                                                }}>(12)</span></h1>
                                                                <h3 style={{
                                                                    fontSize: `${tractStatusTitleFontSize}px`
                                                                }}>Delivered on 6th nov 2023</h3>
                                                            </div>
                                                            <div>
                                                                {
                                                                    orderProductListPopup?.map((productDetails, id) => {
                                                                        return (<>
                                                                            <div className="viewOrderOrderProductDetailsListMyOrder_AccountSection">
                                                                                <div className="viewOrderOrderDetailsCardMyOrder_AccountSection">
                                                                                    <div className="viewOrderCardDetailsLeftSectionMyOrder_AccountSection">
                                                                                        <div className="viewOrderleftInnerCardDetailsMyOrder_AccouontSection">
                                                                                            <div className="viewOrderImageLeftInnerCardDetailsMyOrder_AccountSection">
                                                                                                <Badge color="secondary" badgeContent={productDetails.numberOfQuantity}>
                                                                                                    <img src={productDetails.img} />
                                                                                                </Badge>
                                                                                            </div>
                                                                                            <div className="viewOrderContentLeftInnerCardDetailsMyOrder_AccountSection">
                                                                                                <h1 style={{
                                                                                                    fontSize: `${orderListPopupTitleFontSize * 0.9}px`
                                                                                                }}>{productDetails.title}</h1>
                                                                                                <h4 style={{
                                                                                                    fontSize: `${orderListPopupTitleFontSize * 0.7}px`
                                                                                                }}>{productDetails.size}</h4>
                                                                                                <h2 style={{
                                                                                                    fontSize: `${orderListPopupTitleFontSize * 0.8}px`
                                                                                                }}>₹ {productDetails.price} <span>&nbsp;₹ {productDetails.actualPrice}</span></h2>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="viewOrderCardDetailsRightSectionMyOrder_AccountSection">
                                                                                        <h2 style={{
                                                                                            fontSize: `${orderListPopupTitleFontSize * 0.8}px`
                                                                                        }}>₹ {productDetails.price}</h2>
                                                                                        <h3 style={{
                                                                                            fontSize: `${orderListPopupTitleFontSize * 0.8}px`
                                                                                        }}> cancel item</h3>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>)
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    orderProductList?.map((item, id) => {
                                                        return (<>
                                                            <div className="orderDetailsMainDivCardMyOrder_AccountSection">

                                                                <div className="orderDetailsCardMyOrder_AccountSection">
                                                                    <div className="orderDateDetailsMyOrder_AccountSection">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 9 9" fill="none">
                                                                            <circle cx="4.5" cy="4.5" r="4.5" fill={`${item.indicator}`} />
                                                                        </svg>
                                                                        <h2 style={orderDateDetailsStyles}> &nbsp;&nbsp;{item.orderDate} <span>{item.status}</span></h2>
                                                                    </div>
                                                                    <div className="orderDetailsInnerCardMyOrder_accountSection">
                                                                        <div className="orderDetailsInnerLeftCardMyOrder_accountSection">
                                                                            {
                                                                                item.productImg?.slice(0, 3)?.map((imgs, id) => {
                                                                                    console.log("checking length", item.productImg.length)
                                                                                    return (<>
                                                                                        <img src={imgs.img} />
                                                                                    </>)
                                                                                })
                                                                            }
                                                                            <h2 style={{
                                                                                fontSize: `${paymentMethodDetailsFontSize}px`,
                                                                                color: '#707070'
                                                                            }}> &nbsp; &nbsp;+ &nbsp;
                                                                                {
                                                                                    item.productImg.length - 2
                                                                                }
                                                                                &nbsp; more
                                                                            </h2>
                                                                        </div>
                                                                        <div className="orderDetailsInnerRightCardMyOrder_accountSection">
                                                                            <div className="orderDetailsBtnMainDiv_AccountSection">
                                                                                
                                                                                <div className="group-btns">
                                                                                {
                                                                                    /Delivered|Return/.test(item.orderDate) ? null :
                                                                                        <button
                                                                                            onClick={() => setActiveTrackOrder(true)}
                                                                                            style={{
                                                                                                background: "#1C1C1C",
                                                                                                color: "white",
                                                                                                fontSize: `${tractOrderBtnFOntSize}px`
                                                                                            }}>
                                                                                            Track order
                                                                                        </button>
                                                                                }

                                                                                <button onClick={() => handleOpenViewDetials(item)} style={{
                                                                                    fontSize: `${tractOrderBtnFOntSize}px`,
                                                                                    cursor: "pointer"
                                                                                }}>
                                                                                    view order details
                                                                                </button>
                                                                                </div>
                                                                                {
                                                                                    /Delivered|Return/.test(item.orderDate) ? null :
                                                                                        <div className="cancellationMainDivMyOrder_AccountSection">
                                                                                            <h2 style={{
                                                                                                fontSize: `${tractOrderBtnFOntSize}px`
                                                                                            }}>request cancellation</h2>
                                                                                        </div>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="paymentMethodDetailsMyOrder_AccountSection">
                                                                    <h2 style={{
                                                                        fontSize: `${paymentMethodDetailsFontSize}px`
                                                                    }}>Payment Method: &nbsp;<span className="modeOfPaymentMyOrder_AccountSection">{item.paymentMode}</span></h2>
                                                                    <h3 style={{
                                                                        fontSize: `${paymentMethodDetailsFontSize * 1.1}px`
                                                                    }}>subtotal: <span className="paymentAmountMyOrder_AccountSection">&nbsp; &nbsp;₹ {item.subtotal}</span></h3>
                                                                </div>
                                                            </div>
                                                        </>)
                                                    })
                                                )
                                        }


                                    </div>
                                </div> : null
                        }

                    </div>
                </div>
                {
                    orderProdutPopup ?
                        <div className="quickViewMainDivTrackOrder_MyOrder_AccountSection">
                            <div className="innerOrderDetailsTrackOrder_AccountSection">
                                <div className="headerOrderDetailsTrackOrder_AccountSection">
                                    <div className="upperHeaderOrderDetailsTrackOrder_AccountSection">
                                        <h1 style={{
                                            fontSize: `${orderDetailsTrackOrderFontSize}px`
                                        }}>Order products <span style={{
                                            fontSize: `${orderDetailsTrackOrderFontSize * 0.8}px`
                                        }}>&nbsp;(10 items)</span></h1>
                                        <h3 style={{
                                            fontSize: `${orderDetailsTrackOrderFontSize * 0.8}px`
                                        }}>you can see here all the products you ordered</h3>
                                    </div>
                                    <div className="lowerHeaderOrderDetailsTrackOrder_AccountSection">
                                        <img onClick={() => setorderProductPopup(false)} src={CrossIcon} />
                                    </div>
                                </div>
                                <div className="trackOrderProductDetailsListMainDIvMyOrder_AccountSection">
                                    {
                                        orderProductListPopup?.map((productDetails, id) => {
                                            return (<>
                                                <div className="trackOrderProductDetailsListMyOrder_AccountSection">
                                                    <div className="trackOrderOrderDetailsCardMyOrder_AccountSection">
                                                        <div className="cardDetailsLeftSectionMyOrder_AccountSection">
                                                            <div className="leftInnerCardDetailsMyOrder_AccouontSection">
                                                                <div className="ImageLeftInnerCardDetailsMyOrder_AccountSection">
                                                                    <Badge color="secondary" badgeContent={productDetails.numberOfQuantity}>
                                                                        <img src={productDetails.img} />
                                                                    </Badge>
                                                                </div>
                                                                <div className="contentLeftInnerCardDetailsMyOrder_AccountSection">
                                                                    <h1 style={{
                                                                        fontSize: `${orderListPopupTitleFontSize}px`
                                                                    }}>{productDetails.title}</h1>
                                                                    <h4 style={{
                                                                        fontSize: `${orderListPopupTitleFontSize * 0.6}px`
                                                                    }}>{productDetails.size}</h4>
                                                                    <h2 style={{
                                                                        fontSize: `${orderListPopupTitleFontSize}px`
                                                                    }}>₹ {productDetails.price} <span>&nbsp;₹ {productDetails.actualPrice}</span></h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="cardDetailsRightSectionMyOrder_AccountSection">
                                                            <h2 style={{
                                                                fontSize: `${orderListPopupTitleFontSize}px`
                                                            }}>₹ {productDetails.price}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)
                                        })
                                    }
                                </div>

                            </div>
                        </div>
                        : null
                }
            </div>
        </Layout>
    )
}