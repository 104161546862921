export const pagePaths = {
    root: "/",
    productDetails:'/product-details',
    contactUs:"/contact-us",
    writeReview:"/write-review",
    accountSection:"/account-section",
    shippingDetailsPage:"/shipping-details",
    offerPage:"/offer-page",
    privacyPolicy:"/privacy-policy",
    faq:"/faq",
    termsAndConditons:"/terms-and-conditons",
    cancellationReturnAndExchange:"/cancellation-return-exchange",
    tremsAndServices:"/trems-and-services",
    refundPolicy:"/refund-policy",
    aboutUsPage:"/about-us",
    productLandingPage:"/product-landing",
    wishlist:"/wishlist",
    cart:"cart",
    shopByCategories:"/shop-by-categories"
}