import React from "react";
import "../styles/bannerBelowCategories.css";
import BannerImg1 from "../images/bannerBelowCategoriesImg1.png"
import BannerImg2 from "../images/bannerBelowCategoriesImg2.png"

const widthOutput = window.screen.width;
const bannerTitleFontSize = (1.8 / 100) * widthOutput;
const bannerBrandFontSize = (1.3 / 100) * widthOutput;

const shopNowBtnWidth = (9 / 100) * widthOutput;
// const shopNowBtnHeight = (2/100)*widthOutput;


const bannerTitleStyle = {
    fontSize: `${bannerTitleFontSize}px`,
    textTransform: "capitalize",

}
const bannerContentStyle = {
    fontSize: `${bannerBrandFontSize}px`,
    textTransform: "capitalize",
    fontWeight: 100,
    paddingTop: "0.1rem",
    fontFamily: "Poppins-Regular"

}


export const BannerBelowCategories = () => {
    return (<>
        <div className="mainDiv_BannerBelowCategories">
            <div className="InnerMainDiv_BannerBelowCategories">
                <div className="gridBox_BannerBelowCategories">
                    <div className="cardGrid_BannerBelowCategories">
                        <div className="InnerMainCardGrid_BannerBelowCategories">
                            <div className="ImgCardGrid_BannerBelowCategories">
                                <img src={BannerImg1} />
                            </div>
                            <div className="contentDetails_BannerBelowCategories">
                                <h1 style={bannerTitleStyle}>sunflower refined oil</h1>
                                <h2 style={bannerContentStyle}>From sunpure</h2>
                                <div style={{
                                    width: `${shopNowBtnWidth}px`
                                }} className="shopNowBtn_BannerBelowCategories">
                                    <button>
                                        Shop Now
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div className="InnerMainCardGrid_BannerBelowCategories">
                            <div className="ImgCardGrid_BannerBelowCategories">
                                <img src={BannerImg2} />
                            </div>
                            <div className="contentDetails_BannerBelowCategories secondBanner_BannerBelowCategories">
                                <h1 style={bannerTitleStyle}>sunflower refined oil</h1>
                                <h2 style={bannerContentStyle}>From RISSO</h2>
                                <div style={{
                                    width: `${shopNowBtnWidth}px`
                                }} className="shopNowBtn_BannerBelowCategories">
                                    <button>
                                        Shop Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}